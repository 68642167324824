@import '../../Utilities/Variables';

.Grid {
  > .outer {
    grid-gap: 100px;
    justify-content: center;
    align-items: center;
    display: grid;

    > .inner {
      grid-gap: 30px;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0;
      display: grid;

      > .item {
        display: none;
        height: 100%;

        &.active {
          display: block;
        }
      }
    }

    > .paginationContainer {
      padding: 0 5%;

      > .inner {
        max-width: 1440px;
        text-align: center;

        > .Button {
          > .outer {
            display: inline-block;
            width: auto;

            > .inner {
              display: inline-block;
              width: auto;

              p {
                font-weight: 600;
              }

              a {
                font-weight: 600;
              }
            }
          }
        }
      }

      &.link {
        > .inner {
          > .Button {
            > .outer {
              > .inner {
                a {
                  display: inline-block;
                  width: auto;
                  padding: 0 40px;
                  color: $clrMustard;
                }
              }
            }
          }
        }
      }

      &.loadMore {
        > .inner {
          > .Button {
            > .outer {
              > .inner {
                p {
                  display: inline-block;
                  width: auto;
                  padding: 0 40px;
                  color: $clrMustard;
                }
              }
            }
          }
        }
      }

      &.paged {
        > .inner {
          > .Button {
            > .outer {
              > .inner {
                p {
                }
              }
            }
          }
        }
      }
    }
  }

  &.grid-3-3-1 {
    > .outer {
      > .inner {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 100px;
        display: grid;
      }
    }
  }
}

.GridBlogCard {
  height: 100%;
  display: block;

  > .inner {
    height: 100%;
    padding: 0;
    position: relative;
    align-content: stretch;
    display: grid;

    > a {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 3;
    }

    .contentContainer {
      padding: 20px;
      padding-bottom: 50px;
      height: 100%;
      grid-gap: 30px;
      position: relative;
      align-content: stretch;
      display: grid;

      .TiltContainer {
        .tilt {
          height: 105%;
          top: -5%;
          width: 110%;
          left: -5%;
        }
      }

      > .inner {
        grid-gap: 20px;
        justify-content: start;
        align-content: center;
        align-items: center;
        padding: 0;
        display: grid;

        h5 {
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        p {
          text-align: left;
        }

        .tagContainer {
          > .inner {
            display: block;
            padding: 0;
            text-align: left;

            .tag {
              display: inline-block;
              width: auto;
              margin: 0 10px 10px 0;

              > .inner {
                display: inline-block;
                width: auto;
                padding: 0;
              }
            }
          }
        }
      }
    }

    > .Button {
      position: absolute;
      left: 0;
      bottom: -5%;
      width: 100%;
    }

    &.withImage {
      .contentContainer {
        height: auto;

        .TiltContainer {
          .tilt {
            height: 110%;
          }
        }

        > .inner {
          h5 {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .ImageContainer {
        z-index: 1;
        max-height: 150px;

        > .inner {
          max-height: 150px;

          img {
            max-height: 150px;
          }
        }
      }
    }
  }
}

.GridAboutCard {
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 200px 0;

  > .inner {
    max-width: 1100px;
    width: 60%;
    display: grid;
    grid-template-columns: 5fr 6fr;
    align-content: center;
    justify-content: center;
    align-items: center;

    .imageParent {
      max-height: 600px;
    }

    .contentContainer {
      > .inner {
        padding-top: 50px;

        .title {
          display: inline-block;
          width: auto;
          position: absolute;
          top: -25px;
          left: 0;

          > .inner {
            transform: translateY(-50%);

            h2 {
              font-size: 70px;
              line-height: 80px;
              color: $clrWhite;
              font-family: $fntDisplay;
              padding: 0px 20px;
            }
          }
        }

        .copy {
          > .inner {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 0px;
            align-content: center;
            justify-content: start;
            align-items: center;

            P {
              text-align: left;
            }

            > .Button {
              display: inline-block;
              width: auto;
              margin-left: 0;

              .outer {
                .inner {
                  a {
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $desktopSmallLimitWidth) {
  .GridAboutCard {
    > .inner {
      grid-template-columns: 1fr;
      max-width: 600px;
      width: 45%;

      .imageParent {
        max-height: 450px;
        overflow: hidden;
      }

      .contentContainer {
        > .inner {
          padding-top: 30px;

          .title {
            > .inner {
              transform: translateY(-50%);

              h2 {
                font-size: 40px;
                line-height: 50px;
                padding: 10px;
              }
            }
          }

          .copy {
            > .inner {
              P {
                font-size: 15px;
                line-height: 25px;
              }

              > .Button {
                .outer {
                  .inner {
                    a {
                      font-size: 15px;
                      line-height: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $tabletLimitWidth) {
  .Grid {
    &.grid-3-3-1 {
      > .outer {
        > .inner {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 50px;
        }
      }
    }
  }
}

@media (max-width: $mobileLargeLimitWidth) {
  .Grid {
    &.grid-3-3-1 {
      > .outer {
        > .inner {
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 100px;

          .item {
            display: inline-block;
            width: auto;
            max-width: 400px;
          }

          .item:nth-child(odd) {
            margin-right: -25px;
          }

          .item:nth-child(even) {
            margin-left: 25px;
          }
        }
      }
    }
  }

  .GridAboutCard {
    padding: 150px 0 200px 0;

    > .inner {
      grid-template-columns: 1fr;
      max-width: 600px;
      width: 80%;

      .imageParent {
        max-height: 450px;
        overflow: hidden;
      }

      .contentContainer {
        > .inner {
          padding-top: 30px;

          .title {
            > .inner {
              transform: translateY(-50%);

              h2 {
                font-size: 40px;
                line-height: 50px;
                padding: 10px;
              }
            }
          }

          .copy {
            > .inner {
              P {
                font-size: 15px;
                line-height: 25px;
              }

              > .Button {
                .outer {
                  .inner {
                    a {
                      font-size: 15px;
                      line-height: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .GridBlogCard {
    > .inner {
      > .Button {
        bottom: -10%;
      }

      &.withImage {
        > .Button {
          bottom: -5%;
        }
      }
    }
  }
}

@media (max-width: $mobileMediumLimitWidth) {
  .Grid {
    &.grid-3-3-1 {
      > .outer {
        > .inner {
          .item {
            max-width: 300px;
          }
        }
      }
    }
  }
}
