@import '../../Utilities/Variables';

#ServicePage,
#ServiceChild {
  section.introSection {
    position: relative;
    z-index: 3;

    .backgroundImage {
      position: absolute;
      left: -30%;
      max-width: 800px;
      max-height: 700px;
      overflow: hidden;
      top: -100px;
    }

    > .outer {
      > .inner {
        display: grid;
        align-content: center;
        justify-content: center;
        align-items: center;
        grid-gap: 50px;
        max-width: 700px;
        margin-right: 0;
        padding: 0;

        > div {
          display: grid;
          align-content: center;
          justify-content: center;
          align-items: center;
          grid-gap: 50px;
        }

        .header {
          position: relative;
          margin-left: 0;
          display: inline-block;
          width: auto;

          h1 {
            display: inline-block;
            width: auto;
            margin-left: 0;
            position: relative;
            color: $clrWhite;
            translate: -25% 0;
            max-width: 500px;

            .TiltWrapper {
              padding: 0.25em 0.5em;
              display: inline-block;
              width: auto;
              margin-bottom: 10px;
            }
          }
        }

        .Button {
          margin-left: 0;
        }

        .quote-container {
          display: block;
          padding-top: 50vh;
          padding-bottom: 20vh;
          width: calc(100% + 200px);
          translate: -200px 0;

          > .inner {
            padding: 0;

            .quote {
              font-family: $fntDisplay;
              position: relative;
              color: $clrWhite;
              font-style: italic;
              padding: 0;

              &::before,
              &::after {
                content: '“';
                position: absolute;
                top: 0;
                left: 0;
                translate: -100% -50%;
                font-size: 70px;
                line-height: 1;
                display: inline-block;
                width: auto;
                color: $clrWhite;
                font-family: $fntDisplay;
              }

              &::after {
                display: inline-block;
                top: unset;
                left: unset;
                rotate: 180deg;
                translate: 0 50%;
                position: relative;
              }

              .quoteContent {
                .TiltWrapper {
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  padding: 0.25em;
                  margin-bottom: 0.2em;

                  &:first-child {
                    padding-left: 0.5em;
                  }

                  &:last-child {
                    padding-right: 0.5em;
                  }
                }
              }

              .author {
                font-family: $fntDisplay;
                font-style: italic;
                position: absolute;
                color: $clrBlack;
                bottom: 0;
                right: 0;
                display: inline-block;
                width: auto;
                translate: 0 150%;
              }
            }
          }
        }
      }
    }
  }

  section.USPSection {
    position: relative;
    z-index: 3;

    > .background {
      width: 300vw;
      position: absolute;
      top: 0px;
      left: -100vw;
      max-width: unset;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -100px;
        left: 0;
        height: 0;
        border-style: solid;
        border-width: 0px 0px 100px 300vw;
        border-color: transparent transparent #13121c transparent;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -100px;
        left: 0;
        height: 0;
        border-style: solid;
        border-width: 0px 0px 100px 300vw;
        border-color: transparent transparent #13121c transparent;
        transform: rotate(180deg);
      }
    }

    .backgroundImage {
      position: absolute;
      right: -20%;
      max-width: 700px;
      overflow: hidden;
      top: -200px;
      max-height: 900px;
    }

    > .outer {
      > .inner {
        padding: 50px;

        > ul {
          max-width: 60%;
          margin-left: 0;
          display: grid;
          align-content: center;
          justify-content: start;
          align-items: center;
          grid-gap: 100px;
          grid-template-columns: 1fr;

          > li {
            display: grid;
            align-content: center;
            justify-content: center;
            align-items: start;
            grid-template-columns: 30px 1fr;
            grid-template-areas:
              'icon title'
              'icon body';
            grid-gap: 10px 20px;

            > .icon {
              grid-area: icon;
            }

            > h4 {
              grid-area: title;
              color: $clrWhite;
              font-size: 30px;
              line-height: 1.6;
              letter-spacing: 0.01em;

              > .TiltWrapper {
                display: inline;
                padding: 0 5px;
                vertical-align: top;
              }
            }

            > p {
              grid-area: body;
            }
          }
        }
      }
    }
  }

  section.gridSection {
    position: relative;
    z-index: 3;

    > .outer {
      > .inner {
        > .intro {
          > p {
            margin-top: 50px;
          }
        }

        > .gridContainer {
          padding-top: 100px;

          > .inner {
            display: grid;
            grid-template-columns: repeat(18, 1fr);
            grid-gap: 50px;
            align-content: center;
            justify-content: center;
            align-items: start;
            padding: 0;

            > .item {
              position: relative;
              height: 100%;
              min-height: 300px;
              background-color: $clrBlack;

              > .background {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.8;

                .ImageContainer {
                  height: 100%;

                  > .inner {
                    height: 100%;

                    img {
                      min-height: 100%;
                    }
                  }
                }
              }

              > .linkWrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-size: 0;
                line-height: 0;
                overflow: hidden;
                z-index: 3;
              }

              > .inner {
                padding: 50px;
                position: relative;
              }
            }
          }
        }
      }
    }

    &.marketing {
      > .outer {
        > .inner {
          > .gridContainer {
            > .inner {
              grid-template-areas:
                'one one one one one one one one one one one one two two two two two two'
                'one one one one one one one one one one one one three three three three three three'
                'four four four four four four four four five five five five five five five five five five'
                'six six six six six six seven seven seven seven seven seven eight eight eight eight eight eight';

              > .item {
                &:nth-child(1) {
                  grid-area: one;
                }

                &:nth-child(2) {
                  grid-area: two;
                  transform: translateY(-10%);
                }

                &:nth-child(3) {
                  grid-area: three;
                }

                &:nth-child(4) {
                  grid-area: four;
                  height: 90%;
                }

                &:nth-child(5) {
                  grid-area: five;
                  min-height: 500px;
                  transform: translateY(-2.5%);
                }

                &:nth-child(6) {
                  grid-area: six;
                  transform: translateY(-10%);
                }

                &:nth-child(7) {
                  grid-area: seven;
                }

                &:nth-child(8) {
                  grid-area: eight;
                }
              }
            }
          }
        }
      }
    }

    &.brand {
      > .outer {
        > .inner {
          > .gridContainer {
            > .inner {
              grid-template-areas:
                'one one one one one one one one one one one one two two two two two two'
                'one one one one one one one one one one one one three three three three three three';

              > .item {
                &:nth-child(1) {
                  grid-area: one;
                }

                &:nth-child(2) {
                  grid-area: two;
                  transform: translateY(-10%);
                }

                &:nth-child(3) {
                  grid-area: three;
                }
              }
            }
          }
        }
      }
    }

    &.technology {
      > .outer {
        > .inner {
          > .gridContainer {
            > .inner {
              grid-template-areas: 'one one one one one one one one two two two two two two two two two two';

              > .item {
                &:nth-child(1) {
                  grid-area: one;
                  height: 90%;
                }

                &:nth-child(2) {
                  grid-area: two;
                  min-height: 500px;
                  transform: translateY(-2.5%);
                }
              }
            }
          }
        }
      }
    }
  }

  section.joinItUpSection {
    position: relative;
    min-height: 800px;

    > .background {
      min-height: 800px;
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
      background-position: bottom left;
      background-size: cover;
      background-repeat: no-repeat;
      max-width: 1600px;
    }

    > .outer {
      > .inner {
        height: 100%;
        min-height: 800px;
        display: grid;
        align-content: center;
        justify-content: start;
        align-items: center;
        grid-gap: 30px;

        > div {
          > .inner {
            display: grid;
            grid-template-columns: repeat(3, auto);
            align-content: center;
            justify-content: start;
            align-items: center;
            grid-gap: 30px;
            padding: 0;

            > div {
              &.active {
                > .Button {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
  }

  section.clientCarousel,
  section.partnerCarousel {
    position: relative;
    min-height: 100vh;

    > .background {
      position: absolute;
      top: 0;
      left: -100vw;
      width: 300vw;
      height: 100%;
      max-width: unset;
      background-color: $clrBlack;
    }

    > .outer {
      > .inner {
        height: 100%;
        min-height: 100vh;
        display: grid;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        grid-gap: 20vh;

        > h4 {
          text-align: center;

          .Button {
            p {
              color: $clrBlack !important;
            }
          }
        }
      }
    }
  }

  section.partnerCarousel {
    min-height: unset;
    padding-top: 100px;
    box-sizing: content-box;

    > .outer {
      > .inner {
        min-height: unset;
      }
    }
  }

  .OurWorkSection {
    > .inner {
      padding: 0;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      max-width: unset;
      display: grid;

      .titleContainer {
        .Button {
          margin-left: 0;
        }
      }

      .SlideCarousel {
        > .outer {
          .arrowContainer {
            &.right {
              > .inner {
                .arrow {
                  > .inner {
                    border-left: 10px solid #f27250;
                  }
                }
              }
            }

            &.left {
              > .inner {
                .arrow {
                  > .inner {
                    border-right: 10px solid #f27250;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#ServiceChild {
  section.BasicContentSection {
    padding: 0 50px;

    > .outer {
      > .inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 50px;
        align-content: center;
        justify-content: start;
        align-items: center;

        span.TiltWrapper {
          display: inline;
          padding: 0 5px;
          vertical-align: baseline;
        }
      }
    }
  }
}

.PartnerCarousel {
  &.centered {
    > .outer {
      > .inner {
        justify-content: center;
      }
    }
  }

  > .outer {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'carousel carousel' 'leftButton rightButton';
    align-content: center;
    justify-content: center;
    align-items: center;
    grid-gap: 15vh 20px;
    padding-bottom: 20px;

    > .inner {
      display: grid;
      align-content: center;
      justify-content: start;
      align-items: center;
      grid-gap: 100px;
      position: relative;
      z-index: 3;
      padding: 0;
      grid-area: carousel;
      transition: transform 1000ms ease;

      .item {
        > div {
          position: relative;

          .linkWrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            font-size: 0;
          }
        }
      }
    }

    .arrowContainer {
      height: 100%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: relative;
      display: grid;
      max-width: 50px;

      &.left {
        grid-area: leftButton;
        margin-right: 0;

        > .inner {
          padding: 0;

          .arrow {
            > .inner {
              padding: 0;
              border-bottom: 10px solid transparent;
              border-top: 10px solid transparent;
              border-right: 10px solid $clrBlack;
            }
          }
        }
      }

      &.right {
        grid-area: rightButton;
        margin-left: 0;

        > .inner {
          padding: 0;
          .arrow {
            > .inner {
              padding: 0;
              border-bottom: 10px solid transparent;
              border-top: 10px solid transparent;
              border-left: 10px solid $clrBlack;
            }
          }
        }
      }

      > .inner {
        height: 50px;
        padding: 0;

        .arrow {
          height: 100%;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          cursor: pointer;
          display: grid;

          > .inner {
            width: 0px;
            height: 20px;
            border-radius: 3px;
            padding: 0;
          }
        }
      }
    }
  }
}

.QuoteContainer {
  text-align: center;
  position: relative;
  padding: 50px 0;

  &::before {
    content: '';
    height: 1px;
    width: 80%;
    max-width: 350px;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    content: '';
    height: 1px;
    width: 80%;
    max-width: 350px;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.ServicesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 20px;
  align-content: center;
  justify-content: space-between;
  align-items: end;

  li {
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1300px) {
  #ServicePage,
  #ServiceChild {
    section.introSection {
      .backgroundImage {
        left: unset;
        right: calc(50px + 460px + 10px);
      }

      > .outer {
        > .inner {
          max-width: 460px;
        }
      }
    }

    section.USPSection {
      > .outer {
        > .inner {
          > ul {
            max-width: 50%;
          }
        }
      }
    }

    section.gridSection {
      > .outer {
        > .inner {
          > .gridContainer {
            > .inner {
              > .item {
                > .inner {
                  padding: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  #ServicePage,
  #ServiceChild {
    section.USPSection {
      .background {
        &::before {
          top: -80px;
          border-width: 0px 0px 100px 400vw;
        }

        &::after {
          bottom: -80px;
          border-width: 0px 0px 100px 400vw;
        }
      }
      .backgroundImage {
        display: none;
      }

      > .outer {
        > .inner {
          padding: 50px 20px;

          ul {
            max-width: unset;
            grid-gap: 50px;
          }
        }
      }
    }

    section.gridSection {
      &.active {
        > .outer {
          > .inner {
            .gridContainer {
              > .inner {
                grid-template-areas: 'one' 'two' 'three' 'four' 'five' 'six' 'seven' 'eight';

                .item {
                  &:nth-child(n + 5) {
                    display: block;
                  }
                }
              }
            }

            > .Button {
              margin-top: 20px;
            }
          }
        }

        &.marketing {
          > .outer {
            > .inner {
              > .gridContainer {
                > .inner {
                  grid-template-areas:
                    'one'
                    'two'
                    'three'
                    'four'
                    'five'
                    'six'
                    'seven'
                    'eight';
                }
              }
            }
          }
        }

        &.brand {
          > .outer {
            > .inner {
              > .gridContainer {
                > .inner {
                  grid-template-areas:
                    'one'
                    'two'
                    'three';
                }
              }
            }
          }
        }

        &.technology {
          > .outer {
            > .inner {
              > .gridContainer {
                > .inner {
                  grid-template-areas: 'one' 'two';
                }
              }
            }
          }
        }
      }

      > .outer {
        > .inner {
          padding: 0 20px;
          text-align: center;

          .intro {
            text-align: left;
          }

          .gridContainer {
            > .inner {
              grid-template-columns: 100%;
              grid-gap: 20px;
              text-align: left;

              .item {
                transform: none !important;
                min-height: unset !important;
                max-width: 600px;

                &:nth-child(odd) {
                  width: 100%;
                  aspect-ratio: 352 / 245;
                }

                &:nth-child(even) {
                  width: 100%;
                  aspect-ratio: 352 / 290;
                }

                &:nth-child(n + 5) {
                  display: none;
                }

                > .inner {
                  padding: 20px;
                }
              }
            }
          }

          > .Button {
            margin: 0 auto;
          }
        }
      }

      &.marketing {
        > .outer {
          > .inner {
            > .gridContainer {
              > .inner {
                grid-template-areas:
                  'one'
                  'two'
                  'three'
                  'four';
              }
            }
          }
        }
      }

      &.brand {
        > .outer {
          > .inner {
            > .gridContainer {
              > .inner {
                grid-template-areas:
                  'one'
                  'two'
                  'three';
              }
            }
          }
        }
      }

      &.technology {
        > .outer {
          > .inner {
            > .gridContainer {
              > .inner {
                grid-template-areas: 'one' 'two';
              }
            }
          }
        }
      }
    }

    section.joinItUpSection {
      position: relative !important;
      transform: unset !important;
      top: 0 !important;
      left: unset !important;
    }

    section.clientCarousel,
    section.partnerCarousel {
      position: relative !important;
      transform: unset !important;
      top: 0 !important;
      left: unset !important;

      > .outer {
        > .inner {
          grid-gap: 10vh;
        }
      }
    }
  }

  .PartnerCarousel {
    &.centered {
      > .outer {
        > .inner {
          grid-template-columns: repeat(1, calc(50% - 50px)) !important;
        }
      }
    }
    > .outer {
      grid-gap: 10vh 20px;
      max-width: 500px;
    }
  }
}

@media (max-width: $mobileLargeLimitWidth) {
  #ServicePage,
  #ServiceChild {
    section.introSection {
      .backgroundImage {
        max-width: 80vw;
        left: 0;
      }

      > .outer {
        > .inner {
          padding-top: 40vw;
          max-width: 90vw;
          margin: 0 auto;

          .header {
            transform: unset !important;
            margin-left: auto;
            margin-right: 20px;
            text-align: right;
            margin-bottom: 10vw;
            translate: 0 0;
            max-width: 350px;

            h1 {
              transform: unset !important;
              translate: 0 0;
            }
          }

          p {
            padding-right: 20px;
          }

          .Button {
            margin-right: 10px;
          }

          .quote-container {
            padding-top: 20vh;
            padding-bottom: 15vh;
            width: 80vw;
            translate: none;

            > .inner {
              .quote {
                &::before,
                &::after {
                  font-size: 30px;
                  top: -2.5%;
                  left: -2.5%;
                }

                &:after {
                  top: unset;
                  bottom: 10px;
                  left: unset;
                  right: -10px;
                  position: absolute;
                }

                .author {
                  translate: 0 100%;
                }
              }
            }
          }
        }
      }
    }

    section.gridSection {
      > .outer {
        > .inner {
          .gridContainer {
            padding-top: 50px;
          }
        }
      }
    }

    section.joinItUpSection {
      position: relative !important;
      transform: unset !important;
      top: 0 !important;

      > .outer {
        > .inner {
          grid-template-columns: 100%;
          padding: 0 20px;
          max-width: 100vw;
          margin-left: 0;

          h3,
          p,
          > div {
            transform: unset !important;
          }

          > div {
            > .inner {
              grid-template-columns: auto auto;

              > div {
                &.active {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    section.clientCarousel,
    section.partnerCarousel {
      position: relative !important;
      transform: unset !important;
      top: 0 !important;

      > .outer {
        > .inner {
          grid-gap: 15vw;
        }
      }
    }

    .OurWorkSection > .inner {
      grid-gap: 50px;
      max-width: calc(100% - 50px);
    }
  }

  #ServiceChild {
    section.BasicContentSection {
      padding: 0 20px;
    }
  }

  .ServicesGrid {
    grid-template-columns: 1fr;

    li {
      padding: 20px 0;
    }
  }
}
