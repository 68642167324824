//Screen Breaks
//widths
$outerContentUpperLimitWidth: 1920px;
$innerContentUpperLimitWidth: 1200px;
$innerContentLowerLimitWidth: 320px;
$desktopSmallLimitWidth: 1440px;
$tabletLimitWidth: 1024px;
$mobileLargeLimitWidth: 768px;
$mobileMediumLimitWidth: 425px;
$mobileSmallLimitWidth: 375px;

//heights
$outerContentUpperLimitHeight: 5000px;
$innerContentUpperLimitHeight: 500px;
$innerContentLowerLimitHeight: 600px;
$mediumLimitHeight: 1000px;
$smallLimitHeight: 880px;

//margins
$desktopMarginWidth: 50px;
$mobileMarginWidth: 25px;
$desktopMarginHeight: 0px;
$mobileMarginHeight: 0px;

//Fonts
$fntDefault: 'Poppins', Arial, Helvetica, sans-serif;
$fntDisplay: 'EB Garamond', Arial, Helvetica, sans-serif;

//Colors
$clrBlack: #13121c;
$clrWhite: #ffffff;
$clrMustard: #fad762;
$clrRed: #f27250;
$clrPink: #f0bec9;
$clrBlue: #84a9b5;
$clrLightMustard: #fcffd4;
