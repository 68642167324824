@import '../../Utilities/Variables';

.Header {
  padding: 30px 0;

  > .outer {
    opacity: 0;
    transition: opacity 500ms ease;

    &.loaded {
      opacity: 1;
    }

    > .inner {
      grid-template-columns: 300px 1fr;
      grid-template-areas: 'logo navigation';
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: grid;

      .logo {
        grid-area: logo;
      }

      nav {
        display: grid;
        grid-area: navigation;

        > .inner {
          padding: 0;
          grid-gap: 100px;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: end;
          display: grid;

          .Button {
            &:hover {
              a {
                color: $clrMustard;
              }
            }
          }

          .active {
            .Button {
              a {
                color: $clrMustard;
              }
            }
          }
        }

        .mobileCATContainer {
          display: none;
        }
      }

      .burgerMenuContainer {
        display: none;
      }
    }
  }

  &.dark {
    background-color: $clrBlack;

    > .outer {
      > .inner {
        nav {
          > .inner {
            .Button,
            .navigation-item {
              .TiltContainer {
                background-color: $clrMustard !important;
              }

              .TopLevel {
                .TiltContainer {
                  .tilt {
                    display: none;
                  }
                }

                .dropDownArrow {
                  svg {
                    path {
                      // fill: $clrWhite;
                      fill: $clrMustard;
                    }
                  }
                }
              }

              .SecondLevel {
                .TiltContainer {
                  background-color: unset !important;
                }

                .SecondLevelItem {
                  .TiltContainer {
                    .tilt {
                      > .inner {
                        // background-color: unset !important;
                      }
                    }
                  }

                  a {
                    color: $clrWhite;
                  }

                  &:hover {
                    .TiltContainer {
                      .tilt {
                        > .inner {
                          background-color: rgb(31, 30, 39) !important;
                        }
                      }
                    }

                    a {
                      color: $clrMustard;
                    }
                  }
                }
              }

              .ThirdLevel {
                .ThirdLevelItem {
                  .TiltContainer {
                    .tilt {
                      > .inner {
                        background-color: unset !important;
                      }
                    }
                  }

                  a {
                    color: $clrWhite;
                  }

                  &:hover {
                    .TiltContainer {
                      .tilt {
                        > .inner {
                          // background-color: $clrMustard !important;
                        }
                      }
                    }

                    a {
                      color: $clrBlack;
                    }
                  }
                }
              }

              a {
                // color: $clrWhite;
                color: $clrMustard;
              }

              .dropDownArrow {
                svg {
                  path {
                    // fill: $clrWhite;
                    fill: $clrMustard;
                  }
                }
              }

              &:hover {
                .TopLevel {
                  .dropDownArrow {
                    svg {
                      path {
                        fill: $clrBlack;
                      }
                    }
                  }
                }

                a {
                  color: $clrBlack;
                }
              }

              &.what-we-do {
                .secondLevelItems {
                  .SecondLevelItem {
                    &:hover,
                    &.active {
                      a {
                        color: $clrBlack;
                      }

                      .dropDownArrow {
                        svg {
                          path {
                            fill: $clrBlack;
                          }
                        }
                      }
                    }

                    &:nth-child(1) {
                      &:hover,
                      &.active {
                        .TiltContainer {
                          .tilt {
                            > .inner {
                              background-color: $clrPink !important;
                            }
                          }
                        }
                      }
                    }

                    &:nth-child(2) {
                      &:hover,
                      &.active {
                        .TiltContainer {
                          .tilt {
                            > .inner {
                              background-color: $clrRed !important;
                            }
                          }
                        }
                      }
                    }

                    &:nth-child(3) {
                      &:hover,
                      &.active {
                        .TiltContainer {
                          .tilt {
                            > .inner {
                              background-color: $clrBlue !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .active {
              .Button,
              .navigation-item {
                .TiltContainer {
                  background-color: $clrMustard;
                  opacity: 1;
                }

                .TopLevel {
                  .TiltContainer {
                    .tilt {
                      display: none;
                    }
                  }
                }

                a {
                  color: $clrBlack;
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          .tilt > .inner {
            background-color: $clrMustard !important;
          }

          > .inner .burgerMenu {
            filter: brightness(0);
          }
        }
      }
    }
  }

  &.red {
    background-color: $clrRed;

    > .outer {
      > .inner {
        .navigation {
          > .inner {
            .Button,
            .navigation-item {
              // &:hover {
              //   a {
              //     color: $clrRed;
              //   }

              //   .dropDownArrow {
              //     svg {
              //       path {
              //         fill: $clrRed;
              //       }
              //     }
              //   }
              // }

              &.what-we-do {
                .secondLevelItems {
                  .SecondLevelItem {
                    &:hover,
                    &.active {
                      a {
                        color: $clrBlack;
                      }

                      .dropDownArrow {
                        svg {
                          path {
                            fill: $clrBlack;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .active {
              .Button,
              .navigation-item {
                a {
                  color: $clrRed;
                }

                .dropDownArrow {
                  svg {
                    path {
                      fill: $clrRed;
                    }
                  }
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          .tilt > .inner {
            background-color: $clrBlack;
          }
        }
      }
    }
  }

  &.pink {
    background-color: $clrPink;

    > .outer {
      > .inner {
        nav {
          > .inner {
            .Button,
            .navigation-item {
              // &:hover {
              //   a {
              //     color: $clrPink;
              //   }

              //   .dropDownArrow {
              //     svg {
              //       path {
              //         fill: $clrPink;
              //       }
              //     }
              //   }
              // }

              &.what-we-do {
                .secondLevelItems {
                  .SecondLevelItem {
                    &:hover,
                    &.active {
                      a {
                        color: $clrBlack;
                      }

                      .dropDownArrow {
                        svg {
                          path {
                            fill: $clrBlack;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .active {
              .Button,
              .navigation-item {
                a {
                  color: $clrPink;
                }

                .dropDownArrow {
                  svg {
                    path {
                      fill: $clrPink;
                    }
                  }
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          .tilt > .inner {
            background-color: $clrBlack;
          }
        }
      }
    }
  }

  &.blue {
    background-color: $clrBlue;

    > .outer {
      > .inner {
        nav {
          > .inner {
            .Button,
            .navigation-item {
              // &:hover {
              //   a {
              //     color: $clrBlue;
              //   }

              //   .dropDownArrow {
              //     svg {
              //       path {
              //         fill: $clrBlue;
              //       }
              //     }
              //   }
              // }

              &.what-we-do {
                .secondLevelItems {
                  .SecondLevelItem {
                    &:hover,
                    &.active {
                      a {
                        color: $clrBlack;
                      }

                      .dropDownArrow {
                        svg {
                          path {
                            fill: $clrBlack;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .active {
              .Button,
              .navigation-item {
                a {
                  color: $clrBlue;
                }

                .dropDownArrow {
                  svg {
                    path {
                      fill: $clrBlue;
                    }
                  }
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          .tilt > .inner {
            background-color: $clrBlack;
          }
        }
      }
    }
  }
}

@media (max-width: $desktopSmallLimitWidth) {
  .Header {
    > .outer {
      > .inner {
        grid-template-columns: 200px 1fr;

        nav {
          > .inner {
            grid-gap: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: $tabletLimitWidth) {
  .Header {
    > .outer {
      > .inner {
        nav {
          > .inner {
            grid-gap: 0px;

            .Button {
              a {
                font-size: 15px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileLargeLimitWidth) {
  .Header {
    > .outer {
      > .inner {
        grid-template-columns: 1fr;
        grid-template-areas: 'logo';
        max-width: 300px;

        .logo {
          .ImageContainer {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            img {
              max-height: 40px;
              -o-object-fit: contain;
              object-fit: contain;
            }
          }
        }

        .navigation {
          position: fixed;
          right: 0;
          top: 90px;
          height: 0vh;
          width: 100vw;
          overflow: hidden;
          overflow-y: scroll;
          -ms-flex-line-pack: justify;
          align-content: space-between;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 0;
          background-color: $clrBlack;
          -webkit-transition: height 200ms ease, padding 200ms ease;
          transition: height 200ms ease, padding 200ms ease;

          &.active {
            height: calc(100vh - 90px);
            padding: 100px 0 40px 0;

            > .inner {
              text-align: center;
              grid-gap: 20px;

              .item {
                .Button {
                  width: 100%;

                  > .inner {
                    width: 100%;

                    .TiltContainer {
                      background-color: #1f1e27 !important;
                    }

                    a {
                      width: 100%;
                      text-align: left;
                    }
                  }

                  &:hover {
                    .TiltContainer {
                      background-color: $clrMustard !important;
                    }
                  }
                }
              }

              .Button {
                > .outer {
                  opacity: 1;
                  -webkit-transition: opacity 100ms ease;
                  transition: opacity 100ms ease;
                }
              }
            }
          }

          &::-webkit-scrollbar {
            display: none;
          }

          > .inner {
            grid-template-columns: 1fr !important;
            text-align: center;
            min-width: 300px;
            padding: 0 5vw;

            .Button {
              > .inner {
                a,
                p {
                  text-align: center;
                  color: $clrMustard;
                }
              }

              &:hover {
                .TiltContainer {
                  background-color: $clrMustard !important;
                }

                a,
                p {
                  color: $clrBlack;
                }
              }
            }
          }

          .mobileCATContainer {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;

            > .inner {
              display: block;
              text-align: center;

              .Button {
                display: inline-block;
                width: auto;
                vertical-align: middle;
                margin: 0 20px 20px 20px;

                .TiltContainer {
                  opacity: 0.1;
                }

                &:hover {
                  .TiltContainer {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          display: block;
          grid-area: burgerMenu;
          position: absolute;
          top: -32px;
          right: 2.5%;
          width: 50px;
          height: 50px;
          cursor: pointer;
          z-index: 4;

          .tiltContainer {
            .tilt {
              height: 105%;
              top: -5%;
            }
          }

          > .inner {
            display: grid;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            .burgerMenu {
              width: 20px;
              height: 50px;
              background-position: center center;
              background-size: contain;
              background-repeat: no-repeat;
            }
          }

          &.open {
            position: fixed;
            top: 0;
          }
        }
      }
    }

    &.dark {
      > .outer {
        > .inner {
          .navigation {
            > .inner {
              .Button {
                .TiltContainer {
                  background-color: $clrBlack !important;
                }

                a {
                  color: $clrMustard;
                }

                &:hover {
                  .TiltContainer {
                    background-color: $clrMustard !important;
                  }
                  a {
                    color: $clrBlack;
                  }
                }
              }

              .active {
                .Button {
                  a {
                    color: $clrMustard;
                  }

                  &:hover {
                    a {
                      color: $clrBlack;
                    }
                  }
                }
              }
            }
          }

          .burgerMenuContainer {
            &.open {
              .tilt {
                > .inner {
                  background-color: $clrBlack !important;
                }
              }

              .inner {
                .burgerMenu {
                  filter: brightness(1);
                }
              }
            }
          }
        }
      }
    }

    &.red,
    &.pink,
    &.blue {
      > .outer {
        > .inner {
          .navigation {
            > .inner {
              .Button {
                .TiltContainer {
                  background-color: $clrBlack !important;
                }

                a {
                  color: $clrMustard;
                }

                &:hover {
                  .TiltContainer {
                    background-color: $clrMustard !important;
                  }

                  a {
                    color: $clrBlack;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileSmallLimitWidth) {
}

.Header {
  > .outer {
    > .inner {
      .logo {
        .ImageContainer {
          &:last-child {
            display: none;
          }
        }
      }

      nav {
        > .inner {
          .Button {
            &:hover {
              a {
              }
            }
          }

          .active {
            .Button {
              a {
              }
            }
          }
        }

        .mobileCATContainer {
        }
      }

      .burgerMenuContainer {
      }
    }
  }

  &.dark {
    > .outer {
      > .inner {
        nav {
          > .inner {
            .Button,
            .navigation-item {
              .TiltContainer {
              }

              .TopLevel {
                .TiltContainer {
                  .tilt {
                  }
                }

                .dropDownArrow {
                  svg {
                    path {
                    }
                  }
                }
              }

              .SecondLevel {
                .TiltContainer {
                }

                .SecondLevelItem {
                  a {
                    color: $clrMustard;
                  }

                  .dropDownArrow {
                    svg {
                      path {
                        fill: $clrMustard;
                      }
                    }
                  }

                  &:hover {
                    .TiltContainer {
                      .tilt {
                        > .inner {
                        }
                      }
                    }

                    a {
                    }
                  }
                }
              }

              .ThirdLevel {
                .ThirdLevelItem {
                  .TiltContainer {
                    .tilt {
                      > .inner {
                        background-color: unset;
                      }
                    }
                  }

                  a {
                    color: $clrMustard;
                  }

                  &:hover {
                    .TiltContainer {
                      .tilt {
                        > .inner {
                          background-color: rgb(31, 30, 39);
                        }
                      }
                    }

                    a {
                      color: $clrMustard;
                    }
                  }
                }
              }

              a {
              }

              .dropDownArrow {
                svg {
                  path {
                  }
                }
              }

              &:hover {
                .TopLevel {
                  .dropDownArrow {
                    svg {
                      path {
                      }
                    }
                  }
                }

                a {
                }
              }

              &.what-we-do {
                .secondLevelItems {
                  .SecondLevelItem {
                    &:hover,
                    &.active {
                      a {
                      }

                      .dropDownArrow {
                        svg {
                          path {
                          }
                        }
                      }
                    }

                    &:nth-child(1) {
                      &:hover,
                      &.active {
                        .TiltContainer {
                          .tilt {
                            > .inner {
                            }
                          }
                        }
                      }
                    }

                    &:nth-child(2) {
                      &:hover,
                      &.active {
                        .TiltContainer {
                          .tilt {
                            > .inner {
                            }
                          }
                        }
                      }
                    }

                    &:nth-child(3) {
                      &:hover,
                      &.active {
                        .TiltContainer {
                          .tilt {
                            > .inner {
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .active {
              .Button,
              .navigation-item {
                .TiltContainer {
                }

                .TopLevel {
                  .TiltContainer {
                    .tilt {
                    }
                  }
                }

                a {
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          .tilt > .inner {
          }

          > .inner .burgerMenu {
          }
        }
      }
    }
  }

  &.red {
    > .outer {
      > .inner {
        .navigation {
          > .inner {
            .Button,
            .navigation-item {
              &:hover {
                a {
                }

                .dropDownArrow {
                  svg {
                    path {
                    }
                  }
                }
              }

              &.what-we-do {
                .secondLevelItems {
                  .SecondLevelItem {
                    &:hover,
                    &.active {
                      a {
                      }

                      .dropDownArrow {
                        svg {
                          path {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .active {
              .Button,
              .navigation-item {
                a {
                }

                .dropDownArrow {
                  svg {
                    path {
                    }
                  }
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          .tilt > .inner {
          }
        }
      }
    }
  }

  &.pink {
    > .outer {
      > .inner {
        nav {
          > .inner {
            .Button,
            .navigation-item {
              &:hover {
                a {
                }

                .dropDownArrow {
                  svg {
                    path {
                    }
                  }
                }
              }

              &.what-we-do {
                .secondLevelItems {
                  .SecondLevelItem {
                    &:hover,
                    &.active {
                      a {
                      }

                      .dropDownArrow {
                        svg {
                          path {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .active {
              .Button,
              .navigation-item {
                a {
                }

                .dropDownArrow {
                  svg {
                    path {
                    }
                  }
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          .tilt > .inner {
          }
        }
      }
    }
  }

  &.blue {
    > .outer {
      > .inner {
        nav {
          > .inner {
            .Button,
            .navigation-item {
              &:hover {
                a {
                }

                .dropDownArrow {
                  svg {
                    path {
                    }
                  }
                }
              }

              &.what-we-do {
                .secondLevelItems {
                  .SecondLevelItem {
                    &:hover,
                    &.active {
                      a {
                      }

                      .dropDownArrow {
                        svg {
                          path {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .active {
              .Button,
              .navigation-item {
                a {
                }

                .dropDownArrow {
                  svg {
                    path {
                    }
                  }
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          .tilt > .inner {
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .Header {
    > .outer {
      > .inner {
        grid-template-columns: 1fr;
        grid-template-areas: 'logo';
        max-width: 300px;

        .logo {
          .ImageContainer {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            img {
              max-height: 40px;
              -o-object-fit: contain;
              object-fit: contain;
            }
          }
        }

        .navigation {
          translate: 100% 0;
          position: fixed;
          right: 0;
          top: 90px;
          height: calc(100vh - 90px);
          width: 100vw;
          overflow: hidden;
          overflow-y: scroll;
          -ms-flex-line-pack: justify;
          align-content: space-between;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 50px 0 100px 0;
          background-color: $clrBlack;
          -webkit-transition: translate 500ms ease;
          transition: translate 500ms ease;

          &.active {
            translate: 0 0;
            padding: 40px 0;

            > .inner {
              .item {
                .Button {
                  width: 100%;

                  > .inner {
                    width: 100%;

                    .TiltContainer {
                      background-color: #1f1e27 !important;
                    }

                    a {
                      width: 100%;
                      text-align: left;
                    }
                  }

                  &:hover {
                    .TiltContainer {
                      background-color: $clrMustard !important;
                    }
                  }
                }

                &.open {
                  z-index: 2;

                  .navigation-item {
                    .SecondLevel {
                      translate: 0 0;
                    }
                  }
                }
              }

              .Button {
                > .outer {
                  opacity: 1;
                  -webkit-transition: opacity 100ms ease;
                  transition: opacity 100ms ease;
                }
              }
            }
          }

          &::-webkit-scrollbar {
            display: none;
          }

          &.what-we-do {
            .TopLevel {
              grid-template-columns: auto auto;
              justify-content: space-between;
            }
          }

          > .inner {
            grid-template-columns: 1fr !important;
            min-width: 300px;
            padding: 0 5vw;
            text-align: center;
            grid-gap: 20px;

            .item {
              max-width: 350px;
              z-index: 1;

              .SecondLevel {
                translate: 100% 0;
                transition: translate 500ms ease;
              }
            }

            .Button {
              > .inner {
                a,
                p {
                  text-align: center;
                  color: $clrMustard;
                }
              }

              &:hover {
                .TiltContainer {
                  background-color: $clrMustard !important;
                }

                a,
                p {
                  color: $clrBlack;
                }
              }
            }
          }

          .mobileCATContainer {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;

            > .inner {
              display: block;
              text-align: center;

              .Button {
                display: inline-block;
                width: auto;
                vertical-align: middle;
                margin: 0 20px 20px 20px;

                .TiltContainer {
                  opacity: 0.1;
                }

                &:hover {
                  .TiltContainer {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }

        .burgerMenuContainer {
          display: block;
          grid-area: burgerMenu;
          position: absolute;
          top: -32px;
          right: 2.5%;
          width: 50px;
          height: 50px;
          cursor: pointer;
          z-index: 4;

          .tiltContainer {
            .tilt {
              height: 105%;
              top: -5%;
            }
          }

          > .inner {
            display: grid;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            padding: 0;

            .burgerMenu {
              width: 20px;
              height: 50px;
              background-position: center center;
              background-size: contain;
              background-repeat: no-repeat;
            }
          }

          &.open {
            position: fixed;
            top: 0;
          }
        }
      }
    }

    &.dark {
      > .outer {
        > .inner {
          .navigation {
            > .inner {
              .Button,
              .navigation-link {
                .TiltContainer {
                  background-color: $clrBlack !important;
                }

                a {
                  color: $clrMustard;
                }

                &:hover {
                  .TiltContainer {
                    background-color: $clrMustard !important;
                  }
                  a {
                    color: $clrBlack;
                  }
                }
              }

              .navigation-item {
                .TiltContainer {
                  background-color: rgb(31, 30, 39) !important;
                }

                a {
                  color: $clrMustard;
                }

                .dropDownArrow {
                  svg {
                    path {
                      fill: $clrMustard !important;
                    }
                  }
                }

                &:hover {
                  a {
                    color: $clrMustard;
                  }
                }

                .SecondLevel .SecondLevelItem .TiltContainer .tilt > .inner {
                  background-color: rgb(31, 30, 39) !important;
                }

                &.what-we-do .secondLevelItems {
                  .SecondLevelItem:nth-child(1),
                  .SecondLevelItem:nth-child(2),
                  .SecondLevelItem:nth-child(3) {
                    &.active,
                    :hover {
                      .TiltContainer .tilt > .inner {
                        background-color: rgb(31, 30, 39) !important;
                      }

                      a {
                        color: $clrMustard;
                      }
                    }
                  }
                }
              }

              .active {
                .Button {
                  a {
                    color: $clrMustard;
                  }

                  &:hover {
                    a {
                      color: $clrBlack;
                    }
                  }
                }

                .navigation-item {
                  a {
                    color: $clrMustard;
                  }

                  .dropDownArrow {
                    svg {
                      path {
                        fill: $clrMustard !important;
                      }
                    }
                  }
                }
              }
            }
          }

          .burgerMenuContainer {
            &.open {
              .tilt {
                > .inner {
                  background-color: $clrBlack !important;
                }
              }

              .inner {
                .burgerMenu {
                  filter: brightness(1);
                }
              }
            }
          }
        }
      }
    }

    &.red,
    &.pink,
    &.blue {
      > .outer {
        > .inner {
          nav {
            > .inner {
              .Button,
              .navigation-item {
                &:hover {
                  .TiltContainer {
                    .tilt {
                      > .inner {
                        background-color: #1f1e27 !important;
                      }
                    }
                  }

                  a {
                    color: $clrMustard !important;
                  }

                  .dropDownArrow {
                    svg {
                      path {
                        fill: $clrMustard !important;
                      }
                    }
                  }

                  a.view-all {
                    &.brand {
                      color: $clrPink !important;
                    }

                    &.marketing {
                      color: $clrRed !important;
                    }

                    &.technology {
                      color: $clrBlue !important;
                    }
                  }
                }

                a.view-all {
                  &.brand {
                    color: $clrPink !important;
                  }

                  &.marketing {
                    color: $clrRed !important;
                  }

                  &.technology {
                    color: $clrBlue !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileMediumLimitWidth) {
  .Header {
    > .outer {
      > .inner {
        .logo {
          .ImageContainer {
            > .inner {
              img {
                max-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
