@import '../../Utilities/Variables.scss';

.NewsletterPopUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 9;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 50px;
  animation: fadeIn 1000ms ease-in-out 1000ms 1 forwards;
  opacity: 0;

  > .background {
    background-color: $clrBlack;
    opacity: 0.5;
  }

  > .inner {
    position: relative;
    padding: 0px;
    background-color: $clrBlack;
    color: #fff;
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    grid-template-areas: 'left right';
    max-width: 750px;

    > button {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
      background: none;
      appearance: none;
      outline: none;
      cursor: pointer;
      z-index: 3;
    }

    > .left {
      grid-area: left;
      height: 100%;
      padding: 0 0 30px 30px;

      .ImageContainer {
        translate: 0px -10px;
        rotate: -1deg;
        transform: skewX(-1deg);
      }
    }

    > .right {
      padding: 60px 30px 160px 30px;
      grid-area: right;

      > p {
        margin-top: 20px;

        &.font-xSmall {
          margin-top: 0;
        }
      }

      form {
        margin-top: 20px;

        #newsletterEmail {
          position: relative;
          margin-bottom: 20px;

          input {
            padding: 0.5em;
            background-color: rgba(255, 255, 255, 0.15);
            color: $clrWhite;
            border: 1px solid transparent;
            transition: border 500ms ease;

            + .inputError {
              display: none;
              position: absolute;
              top: 50%;
              right: 0;
              width: auto;
              height: auto;
              text-align: right;
              padding: 0.5em;
              transform: translateY(-50%);
              color: $clrRed;
              z-index: -1;
            }

            &:focus-within {
              border: 1px solid $clrMustard;
            }

            &.error {
              border-color: $clrRed;

              + .inputError {
                display: block;
              }
            }
          }
        }
      }

      .successMessage {
        > .inner {
          display: grid;
          align-items: center;
          grid-gap: 20px;
          grid-template-columns: 100%;
          padding: 0;
          text-align: left;
          align-content: center;
          justify-content: start;
          min-height: 260px;
        }
      }

      .errorMessage {
        > .inner {
          display: grid;
          align-content: center;
          justify-content: center;
          align-items: center;
          grid-gap: 20px;
          grid-template-columns: 1fr;
          text-align: center;

          .p-small {
            max-width: 500px;
          }

          .font-h6 {
            .font-h6 {
              color: $clrBlack !important;
            }
          }
        }
      }
    }

    > .quoteContainer {
      position: absolute;
      bottom: 30px;
      left: 70px;

      p {
        span {
          display: inline-block;
          width: auto;
          position: relative;

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -1%;
            left: -1%;
            width: 102%;
            height: 102%;
            background-color: $clrMustard;
            z-index: -1;
            transform: skewX(-3deg);
          }

          &:nth-child(1) {
            margin-bottom: 5px;

            &::before {
              transform: skewX(3deg);
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 880px) {
  .NewsletterPopUp {
    padding: 50px 20px;

    > .inner {
      grid-template-columns: 1fr;
      grid-template-areas: 'right';

      > .left {
        display: none;
      }

      > .right {
        padding: 60px 30px 60px 30px;

        .successMessage {
          > .inner {
            text-align: center;
          }
        }
      }

      > .quoteContainer {
        display: none;
      }
    }
  }
}
