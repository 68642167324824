@import '../../Utilities/Variables';

.pageBackground.HowCanWeHelpPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $clrBlack;
}

#HowCanWeHelpPage {
  background-color: $clrBlack;

  > .outer {
    > .inner {
      max-width: 1720px;
    }
  }
}

.ContactSection {
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;

  &.locationOpen {
    > .inner {
      > .right {
        > .outer {
          > .inner {
            > div {
              opacity: 0.6;

              &:nth-child(3) {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  &.emailOpen {
    > .inner {
      > .right {
        > .outer {
          > .inner {
            > div {
              opacity: 0.6;

              &:nth-child(2) {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  > .inner {
    max-width: 1720px;
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: minmax(30%, 650px) minmax(600px, 1fr);
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0;
    grid-gap: 20px;

    > .left {
      > .inner {
        padding: 0;
        position: relative;

        > .ImageContainer {
          max-width: 500px;
          margin-left: 0;
        }

        > h1 {
          position: absolute;
          top: 100px;
          max-width: 350px;
          right: 0;
          width: 110%;

          .Button {
            &:nth-last-child(1) {
              margin-top: 20px;
            }

            > .inner {
              > p {
                padding: 5px 20px;
              }
            }
          }
        }
      }
    }

    > .right {
      > .outer {
        max-width: 700px;
        margin-left: 0;

        > .inner {
          padding: 0;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-content: center;
          justify-content: center;
          align-items: center;
          grid-gap: 20px;
          grid-template-areas: 'whatsapp email location';

          > div {
            width: auto;
            position: relative;
            transition: opacity 500ms ease;

            &:hover {
              opacity: 1;
            }

            &:nth-child(1) {
              grid-area: whatsapp;
            }
            &:nth-child(2) {
              grid-area: email;
            }
            &:nth-child(3) {
              grid-area: location;
            }

            .Button {
              transition: opacity 300ms ease;

              > .inner {
                .TiltContainer {
                  opacity: 0.1;
                }
              }

              &:hover {
                opacity: 1;
              }

              &.active {
                opacity: 1;
              }
            }

            .locations {
              position: absolute;
              bottom: -10px;
              transform: translateY(100%);
              width: 100%;
              height: 0;
              overflow: hidden;

              &.active {
                height: auto;
              }

              ul {
                li {
                  background-color: rgba(255, 255, 255, 0.1);
                  margin-top: 2px;

                  a {
                    color: $clrMustard;
                    padding: 0.5em;
                    transition: color 500ms ease, background 500ms ease;

                    &:hover {
                      color: $clrBlack;
                      background-color: $clrMustard;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// .emailOpen .ContactForm {
//     height: auto;
// }

#contactFormTarget,
#locationDropDown {
  transition: height 500ms ease-in-out;
}

.ContactForm {
  overflow: hidden;

  form {
    padding: 50px 0 50px 0;
    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'fullName fullName'
      'email phone'
      'company company'
      'comment comment'
      'hear hear'
      'submit submit';
    grid-gap: 20px;

    #fullName {
      grid-area: fullName;
      position: relative;
    }

    #email {
      grid-area: email;
      position: relative;
    }

    #phone {
      grid-area: phone;
      position: relative;
    }

    #company {
      grid-area: company;
      position: relative;
    }

    #hear {
      grid-area: hear;
      position: relative;
    }

    #budget {
      grid-area: budget;
      border: 1px solid transparent;
      transition: border 500ms ease;

      &.active {
        border: 1px solid $clrMustard;

        &.error {
          .options {
            border-color: $clrRed;
          }
        }

        .options {
          border-color: $clrMustard;

          li {
            transition: color 500ms ease, background 500ms ease;

            &:hover {
              color: $clrBlack;
              background-color: $clrMustard;
            }
          }
        }
      }

      &.error {
        border-color: $clrRed;
      }

      .options {
        bottom: -2px;
        border: 1px solid transparent;
        border-top: none;
        width: calc(100% + 2px);
        left: -1px;
      }
    }

    #comment {
      grid-area: comment;
      position: relative;
    }

    textarea,
    input,
    .dropDown > p,
    .options li {
      font-size: 16px;
    }

    input,
    textarea {
      padding: 0.5em;
      background-color: rgba(255, 255, 255, 0.15);
      color: $clrWhite;
      border: 1px solid transparent;
      transition: border 500ms ease;

      + .inputError {
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        width: auto;
        height: auto;
        text-align: right;
        padding: 0.5em;
        transform: translateY(-50%);
        color: $clrRed;
        z-index: -1;
      }

      &:focus-within {
        border: 1px solid $clrMustard;
      }

      &.error {
        border-color: $clrRed;

        + .inputError {
          display: block;
        }
      }

      &.submit {
        background: unset;
      }
    }

    textarea {
      min-height: 100px;

      + .inputError {
        top: unset;
        bottom: 0;
        transform: unset;
      }

      &.initial {
        color: #757575;
      }
    }

    .Button {
      margin-left: 0px;

      > .inner p {
        font-size: 18px;
      }

      > .inner input {
        color: black;
        position: relative;
        font-weight: bold;
        cursor: pointer;
        font-weight: 600;
        padding: 10px 20px;
        position: relative;
        width: auto;
        font-size: 18px;
      }
    }
  }

  .successMessage {
    padding-top: 100px;

    > .inner {
      display: grid;
      align-content: center;
      justify-content: center;
      align-items: center;
      grid-gap: 20px;
      grid-template-columns: 1fr;
      padding: 50px;
      background-color: rgba(255, 255, 255, 0.1);
      text-align: center;

      .Button > .inner p {
        padding: 10px;
      }

      .p-small {
        max-width: 550px;
      }
    }
  }

  .errorMessage {
    padding-top: 100px;

    > .inner {
      display: grid;
      align-content: center;
      justify-content: center;
      align-items: center;
      grid-gap: 20px;
      grid-template-columns: 1fr;
      padding: 50px;
      background-color: rgba(255, 255, 255, 0.1);
      text-align: center;

      .p-small {
        max-width: 500px;
      }

      .font-h6 {
        .font-h6 {
          color: $clrBlack !important;
        }
      }
    }
  }
}

.dropDown {
  position: relative;
  z-index: 3;

  &.active {
    .options {
      height: auto;
    }
  }

  > p {
    color: $clrWhite;
    padding: 0.5em;
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
  }

  .options {
    height: 0;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    background-color: $clrBlack;

    li {
      color: #fff;
      padding: 0.5em;
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  #howCanWeHelpVariableSpacerOne {
    height: 150px !important;
  }

  .ContactSection {
    padding-bottom: 100px;

    > .inner {
      grid-template-columns: minmax(40%, 650px) minmax(60%, 1fr);

      > .left {
        > .inner {
          > .ImageContainer {
          }

          > h1 {
            right: 0px;
            max-width: 280px;

            .Button {
              p {
                font-size: 45px;
              }

              &:nth-last-child(1) {
              }
            }
          }
        }
      }

      > .right {
        > .outer {
          > .inner {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            grid-template-areas: 'whatsapp whatsapp' 'email location';

            .Button {
              > .inner {
                .TiltContainer {
                }
              }

              &:hover {
              }

              &.active {
              }
            }
          }
        }
      }
    }
  }

  .ContactForm form {
    grid-template-columns: 1fr;
    // grid-template-areas:
    //     "fullName"
    //     "email"
    //     "phone"
    //     "budget"
    //     "comment"
    //     "submit";
    grid-template-areas:
      'fullName'
      'email'
      'phone'
      'company'
      'comment'
      'hear'
      'submit';
    padding: 50px 0;

    .Button {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      > .inner {
        max-width: 300px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .ContactSection {
    > .inner {
      grid-template-columns: 1fr;
      grid-gap: 100px;
      grid-template-areas:
        'left'
        'right';

      > .left > .inner {
        > .ImageContainer {
          max-width: 200px;
        }

        h1 {
          right: unset;
          left: 15vw;
          top: 50px;
        }
      }

      > .right {
        padding: 0 20px;
      }
    }
  }

  .ContactForm form .Button > .inner {
    max-width: 400px;
  }
  .ContactForm form textarea {
    min-height: 200px;
  }
  .ContactForm .successMessage > .inner,
  .ContactForm .errorMessage > .inner {
    padding: 20px;

    .Button > .inner p {
      font-size: 20px;
    }

    .p-small {
      font-size: 14px;
    }
  }
}

@media (max-width: 350px) {
  .ContactSection {
    > .inner {
      > .left {
        > .inner {
          h1 {
            left: 20px;
          }
        }
      }

      > .right {
        > .outer {
          > .inner {
            grid-template-areas:
              'whatsapp'
              'email'
              'location';
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}
