@import '../../Utilities/Variables';

.ImageContainer {
  max-height: 100%;
  overflow: hidden;

  > .inner {
    padding: 0;

    a {
      height: 100%;
    }

    img {
      object-fit: cover;
    }
  }
}
