@import '../../Utilities/Variables';

.pageBackground.WhatWeDoPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $clrMustard;
}

#WhatWeDoPage {
  > .inner {
    .IntroSection {
      > .inner {
        grid-gap: 50px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        padding: 0;
        display: grid;

        .topContainer {
          position: relative;

          > .inner {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 0;
            display: grid;
            min-height: 766px;

            .backgroundImage {
              max-width: 600px;
              width: 80%;

              > .inner {
                padding: 0;
              }
            }

            .titleContainer {
              position: absolute;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              text-align: center;
              display: grid;

              > .inner {
                padding: 0;
                max-width: 750px;
                position: relative;

                .Button {
                  p {
                    font-size: 132px;
                    line-height: 150px;
                  }
                }
              }
            }
          }
        }

        .bottomContainer {
          > .inner {
            padding: 0;

            p {
              text-align: center;
            }
          }
        }
      }
    }

    .AboutSection {
      background-color: $clrMustard;
      position: relative;

      .AboutSectionStatic {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: 9;

        .AboutSectionStaticBackground {
          position: absolute;
          top: 0;
          left: -100vw;
          height: 100%;
          width: 300vw;
          z-index: 10;
          background-color: #f0bec9;
          max-width: unset;
        }

        > .inner {
          padding: 0;
          grid-template-columns: 5fr 6fr;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 0;
          display: grid;
          z-index: 11;
          position: relative;
          padding: 200px 0;

          .imageParent {
            > .inner {
              padding: 0;

              .ImageContainer {
                > .inner {
                  background-position: center center;
                  background-size: cover;
                  background-repeat: no-repeat;
                  width: 100%;
                  height: 650px;
                  max-height: calc(100vh - 400px);
                }

                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  opacity: 0;
                  height: 100%;
                  width: auto;
                }
              }
            }
          }
        }
      }

      > .inner {
        padding: 0;

        .TabbedAboutCard {
          > .inner {
            padding: 0;

            .contentContainer {
              > .inner {
                .title {
                  > .inner {
                    h2 {
                      padding: 10px 20px;
                      position: relative;
                      width: auto;
                    }
                  }
                }
                .copy {
                  > .inner {
                    P {
                      -webkit-line-clamp: 99;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .OurWorkSection {
      position: relative;

      > .background {
        background-color: $clrMustard;
        width: 300vw;
        max-width: unset;
        height: 100%;
        left: -100vw;
        top: 0;
        position: absolute;
      }

      > .inner {
        padding: 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        max-width: unset;
        display: grid;

        .titleContainer {
          .Button {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.Header {
  -webkit-transform: none !important;
  transform: none !important;
}

@media (max-width: $tabletLimitWidth) {
  .WhatWeDoPage {
    > .inner {
      .IntroSection {
        > .inner {
          .topContainer {
            > .inner {
              .titleContainer {
                > .inner {
                  .Button {
                    p {
                      font-size: 100px;
                      line-height: 110px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileLargeLimitWidth) {
  #WhatWeDoPage {
    > .inner {
      .IntroSection {
        > .inner {
          max-width: calc(100vw - (2 * $mobileMarginWidth));

          .topContainer {
            > .inner {
              position: relative;
              min-height: calc(100vw - 50px);

              .titleContainer {
                top: 40%;

                > .inner {
                  .Button {
                    p {
                      font-size: 60px;
                      line-height: 70px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .OurWorkSection {
        > .inner {
          grid-gap: 50px;
          padding-top: 0;
          max-width: calc(100vw - (2 * $mobileMarginWidth));
        }
      }

      .AboutSection {
        .AboutSectionStatic {
          > .inner {
            grid-template-columns: 6fr 2fr;
          }
        }

        > .inner {
          max-width: calc(100vw - (2 * $mobileMarginWidth));
        }
      }
    }
  }
}

@media (max-width: $mobileMediumLimitWidth) {
  .WhatWeDoPage {
    > .inner {
      .IntroSection {
        > .inner {
          grid-gap: 30px;

          .topContainer {
            > .inner {
              .titleContainer {
                > .inner {
                  .Button {
                    p {
                      font-size: 45px;
                      line-height: 55px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
