@import '../../Utilities/Variables';

.LoadingContainer {
  display: grid;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: $clrMustard;
  opacity: 1;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;

  > .inner {
    position: relative;
    width: auto;
    padding: 30px 0;
    max-width: calc(100% - (2 * $desktopMarginWidth));

    .svgContainer {
      overflow: hidden;

      svg {
        -webkit-animation: svgAnimation 2000ms ease-in-out 0ms infinite forwards;
        animation: svgAnimation 2000ms ease-in-out 0ms infinite forwards;
        width: 100%;
        display: inline-block;
        height: auto;
      }
    }

    .box {
      -webkit-animation: boxAnimation 2000ms ease-in-out 0ms infinite forwards;
      animation: boxAnimation 2000ms ease-in-out 0ms infinite forwards;
      position: absolute;
      top: 20%;
      background-color: #000;
      width: 0;
      left: 0;
      height: 60%;
    }
  }

  &.hide {
    opacity: 0;
  }

  &.dark {
    background-color: $clrBlack;

    > .inner {
      .box {
        background-color: $clrMustard;
      }
    }
  }

  &.red {
    background-color: $clrRed;
  }

  &.pink {
    background-color: $clrPink;
  }

  &.blue {
    background-color: $clrBlue;
  }

  @-webkit-keyframes boxAnimation {
    0% {
      width: 0%;
      left: 0;
      right: unset;
    }

    24% {
      width: 100%;
      left: 0;
      right: unset;
    }

    26% {
      width: 100%;
      left: unset;
      right: 0;
    }

    50% {
      width: 0%;
      left: unset;
      right: 0;
    }

    100% {
      width: 0%;
      left: unset;
      right: 0;
    }
  }
  @keyframes boxAnimation {
    0% {
      width: 0%;
      left: 0;
      right: unset;
    }

    24% {
      width: 100%;
      left: 0;
      right: unset;
    }

    29% {
      width: 100%;
      left: 0;
      right: unset;
    }

    30% {
      width: 100%;
      left: unset;
      right: 0;
    }

    50% {
      width: 0%;
      left: unset;
      right: 0;
    }

    100% {
      width: 0%;
      left: unset;
      right: 0;
    }
  }
  @-webkit-keyframes svgAnimation {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    /* 50% {
            opacity: 1;
        } */

    80% {
      opacity: 1;
      -webkit-transform: translateY(0%);
      transform: translateY(0%);
    }

    100% {
      opacity: 0;
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }
  @keyframes svgAnimation {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }

    26% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }

    27% {
      -webkit-transform: translateY(0%);
      transform: translateY(0%);
    }

    80% {
      -webkit-transform: translateY(0%);
      transform: translateY(0%);
    }

    100% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }
}

@media (max-width: $mobileLargeLimitWidth) {
  .LoadingContainer {
    > .inner {
      max-width: calc(100vw - (2 * $mobileMarginWidth));
    }
  }
}
