@import '../../Utilities/Variables';

.pageBackground.HomePage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $clrMustard;
}

#HomePage {
  padding-top: 110px;

  > .outer {
    > .inner {
      .BlogSection {
        position: relative;
        z-index: 3;

        > .background {
          background-color: $clrMustard;
          width: 300vw;
          max-width: unset;
          height: 100%;
          left: -100vw;
          top: 0;
          position: absolute;
        }

        .backgroundImage {
          position: absolute;
          height: 100%;
          top: 80px;
          right: -15vw;
          display: block;

          > .inner {
            padding: 0;
            max-width: unset;

            .ImageContainer {
              -webkit-box-pack: end;
              -ms-flex-pack: end;
              justify-content: end;
              max-width: 500px;
              margin-right: 0;
              display: grid;
            }
          }
        }

        > .inner {
          padding: 0;
        }
      }

      .TestimonialSection {
        position: relative;

        > .background {
          background-color: $clrMustard;
          width: 300vw;
          max-width: unset;
          height: 100%;
          left: -100vw;
          top: 0;
          position: absolute;
        }

        > .inner {
          padding: 0;
        }
      }

      .OurWorkSection {
        > .inner {
          padding: 0;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          max-width: unset;
          display: grid;

          .titleContainer {
            .Button {
              margin-left: 0;
            }
          }
        }
      }

      .IntroSection {
        > .inner {
          grid-gap: 300px;
          display: grid;

          .topSection {
            position: relative;
            z-index: 4;

            > .inner {
              position: relative;
              padding: 0;

              .backgroundImage {
                height: auto;
                position: absolute;
                top: 0;
                right: 0;
                display: block;

                > .inner {
                  padding: 0;
                  height: 100%;

                  .ImageContainer {
                    -webkit-box-pack: end;
                    -ms-flex-pack: end;
                    justify-content: end;
                    max-width: 780px;
                    margin-right: 0;
                    height: 100%;
                    display: grid;
                  }
                }
              }

              .contentContainer {
                > .inner {
                  padding: 0;
                  grid-gap: 30px;
                  -webkit-box-pack: start;
                  -ms-flex-pack: start;
                  justify-content: start;
                  display: grid;

                  h1 {
                    display: inline-block;
                    max-width: 880px;
                    margin-left: 0;

                    > .Button {
                      padding-right: 20px;

                      .TiltContainer {
                        top: -10px;
                        left: -20px;
                        height: calc(100% + 20px);
                        width: calc(100% + 40px);
                      }

                      p {
                        padding: 0;
                      }
                    }
                  }

                  > div {
                    > .Button {
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }

          .bottomSection {
            z-index: 4;
            padding-bottom: 200px;

            > .inner {
              padding: 0;

              .backgroundImage {
                position: absolute;

                > .inner {
                  max-width: 1920px;
                  width: 100%;
                  padding: 0;

                  .ImageContainer {
                    -webkit-box-pack: start;
                    -ms-flex-pack: start;
                    justify-content: start;
                    display: grid;

                    > .inner {
                      max-width: 350px;
                      margin-left: 0;
                    }
                  }
                }
              }

              .contentContainer {
                > .inner {
                  grid-gap: 50px;
                  -webkit-box-pack: end;
                  -ms-flex-pack: end;
                  justify-content: end;
                  padding: 0;
                  display: grid;

                  P {
                    text-align: right;
                  }

                  .Button {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }

      .AboutSection {
        background-color: $clrMustard;
        position: relative;

        .AboutSectionStatic {
          position: absolute;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100%;
          z-index: 9;

          .AboutSectionStaticBackground {
            position: absolute;
            top: 0;
            left: -100vw;
            height: 100%;
            width: 300vw;
            z-index: 10;
            background-color: #f0bec9;
            max-width: unset;
          }

          > .inner {
            padding: 0;
            grid-template-columns: 5fr 6fr;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 0;
            display: grid;
            z-index: 11;
            position: relative;
            padding: 200px 0;

            .imageParent {
              > .inner {
                padding: 0;

                .ImageContainer {
                  > .inner {
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 650px;
                    max-height: calc(100vh - 400px);
                  }

                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    height: 100%;
                    width: auto;
                  }
                }
              }
            }
          }
        }

        > .inner {
          padding: 0;

          .TabbedAboutCard {
            > .inner {
              padding: 0;

              .contentContainer {
                > .inner {
                  .title {
                    > .inner {
                      h2 {
                        padding: 10px 20px;
                        position: relative;
                        width: auto;
                      }
                    }
                  }

                  .copy {
                    > .inner {
                      P {
                        -webkit-line-clamp: 99;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $tabletLimitWidth) {
  #HomePage {
    > .outer {
      > .inner {
        .IntroSection {
          > .inner {
            grid-gap: 150px;

            .topSection {
              > .inner {
                .backgroundImage {
                  > .inner {
                    .ImageContainer {
                      max-width: 550px;
                      max-height: 700px;
                    }
                  }
                }
              }
            }

            .bottomSection {
              > .inner {
                .backgroundImage {
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileLargeLimitWidth) {
  #HomePage {
    > .outer {
      > .inner {
        .BlogSection {
          padding-bottom: 50px;

          > .backgroundImage {
            display: none;
          }

          > .inner {
            max-width: calc(100vw - (2 * $mobileMarginWidth));
          }

          .Grid.grid-3-3-1 > .outer > .inner .item {
            display: none;
          }
          .Grid.grid-3-3-1 > .outer > .inner .item:nth-child(1),
          .Grid.grid-3-3-1 > .outer > .inner .item:nth-child(2) {
            display: inline-block;
          }
        }

        .IntroSection {
          > .inner {
            grid-gap: 200px;
            padding-top: 0;
            max-width: calc(100vw - (2 * $mobileMarginWidth));

            .topSection {
              > .inner {
                padding: 0;
                padding-bottom: 100px;

                .backgroundImage {
                  right: -50px;
                  opacity: 0.8 !important;

                  > .inner {
                    .ImageContainer {
                      max-width: 450px;
                      max-height: 600px;
                    }
                  }
                }

                .contentContainer {
                  > .inner {
                    grid-gap: 50px;

                    h1 {
                      max-width: unset;
                      font-size: 40px;
                      line-height: 45px;
                      letter-spacing: 0.5px;

                      br {
                        display: none;
                      }
                    }

                    .Button {
                      p {
                        font-size: 40px;
                        line-height: 45px;
                        letter-spacing: 0.5px;
                      }
                    }
                  }
                }
              }
            }

            .bottomSection {
              padding-bottom: 0px;

              > .inner {
                .contentContainer {
                  > .inner {
                    grid-gap: 50px;

                    P {
                      text-align: right;
                    }

                    .Button {
                      display: inline-block;
                      width: auto;
                      margin-right: 0;
                    }
                  }
                }

                .backgroundImage {
                  padding-top: 50px;
                  display: none;

                  > .inner {
                    .ImageContainer {
                      max-width: 250px;
                      max-height: 400px;
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }
        }

        .OurWorkSection {
          > .inner {
            grid-gap: 50px;
            padding-top: 0;
            max-width: calc(100vw - (2 * $mobileMarginWidth));
          }
        }

        .AboutSection {
          .AboutSectionStatic {
            > .inner {
              grid-template-columns: 6fr 2fr;
            }
          }

          > .inner {
            max-width: calc(100vw - (2 * $mobileMarginWidth));
          }
        }

        .TestimonialSection {
          > .inner {
            max-width: calc(100vw - (2 * $mobileMarginWidth));

            .SlideCarousel {
              > .outer {
                grid-template-areas:
                  'carousel carousel carousel carousel'
                  '. arrowLeft arrowRight .';
                grid-template-columns: 1fr auto auto 1fr !important;
                justify-content: center;

                .arrowContainer {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileMediumLimitWidth) {
  #HomePage {
    > .outer {
      > .inner {
        .IntroSection {
          > .inner {
            grid-gap: 100px;
            padding-top: 0;
            max-width: calc(100vw - (2 * $mobileMarginWidth));

            .topSection {
              > .inner {
                padding: 0;
                padding-bottom: 100px;

                .contentContainer {
                  > .inner {
                    .Button {
                      p {
                        font-size: 40px;
                        line-height: 45px;
                        letter-spacing: 0.5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .mobileSpacer {
          height: 50px !important;
        }
      }
    }
  }
}
