@import '../../Utilities/Variables';

.TiltContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.tilt {
  position: relative;
  width: 100%;
  height: 105%;
  top: -2.5%;
  left: 0%;
  display: block;

  > .inner {
    height: 100%;
    padding: 0;
  }
}
