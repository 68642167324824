@import '../../Utilities/Variables.scss';

.Button {
  display: inline-block;
  width: auto;
  vertical-align: middle;

  > .inner {
    display: inline-block;
    padding: 0;
    position: relative;
    width: auto;

    .TiltContainer {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
    }

    p,
    a {
      cursor: pointer;
      font-weight: 600;
      padding: 10px 20px;
      position: relative;
      width: auto;

      img {
        display: inline-block;
        margin-right: 5px;
        -o-object-fit: cover;
        object-fit: cover;
        vertical-align: middle;
        width: 30px;
      }
    }

    .loading {
      position: relative;
      padding: 20px 10px;

      > span {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        background-color: $clrBlack;
        margin: 0 2.5px;
        -webkit-transition: -webkit-transform 1s ease-in-out;
        transition: -webkit-transform 1s ease-in-out;
        -o-transition: transform 1s ease-in-out;
        transition: transform 1s ease-in-out;
        transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
        -webkit-animation: loading 1s ease-in-out 0s infinite;
        animation: loading 1s ease-in-out 0s infinite; //name duration timing-function delay iteration-count direction fill-mode play-state

        &:nth-child(2) {
          -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
        }

        &:nth-child(3) {
          -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
        }
      }
    }
  }

  &.tiltHover {
    > .inner {
      .TiltContainer {
        opacity: 0;
        -webkit-transition: opacity 200ms ease;
        -o-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
      }
    }

    &:hover {
      > .inner {
        .TiltContainer {
          opacity: 1;
        }
      }
    }
  }
}

.active .Button.tiltHover {
  > .inner {
    .TiltContainer {
      opacity: 1;
    }
  }
}

.ToTopButton {
  bottom: 30px;
  cursor: pointer;
  height: 50px;
  position: fixed;
  right: 0;
  -webkit-transition: bottom 100ms ease;
  -o-transition: bottom 100ms ease;
  transition: bottom 100ms ease;
  width: 50px;

  .tilt {
    height: 100%;
    left: 0;
    opacity: 0.5;
    top: 0;
    -webkit-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    width: 100%;
  }

  > .inner {
    display: -ms-grid;
    display: grid;
    height: 50px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;

    .toTop {
      border-bottom: 10px solid $clrBlack;
      border-left: 10px solid transparent;
      border-radius: 3px;
      border-right: 10px solid transparent;
      height: 0px;
      width: 20px;
    }
  }

  &:hover {
    .tilt {
      opacity: 0.75;
    }
  }
}

.navigation-item {
  position: relative;

  &.what-we-do {
    .SecondLevel {
      left: -50%;
      grid-gap: 10px 30px;
      -ms-grid-columns: auto 30px 190px;
      grid-template-columns: auto 190px;
      width: auto;

      .SecondLevelItem {
        margin-bottom: 10px;
        min-width: 170px;

        &:hover,
        &.active {
          &:nth-child(1) {
            .TiltContainer {
              .tilt {
                > .inner {
                  background-color: $clrPink !important;
                }
              }
            }
          }

          &:nth-child(2) {
            .TiltContainer {
              .tilt {
                > .inner {
                  background-color: $clrRed !important;
                }
              }
            }
          }

          &:nth-child(3) {
            .TiltContainer {
              .tilt {
                > .inner {
                  background-color: $clrBlue !important;
                }
              }
            }
          }

          a {
            color: $clrBlack;
          }

          .dropDownArrow {
            svg {
              path {
                fill: $clrBlack;
              }
            }
          }
        }

        .TiltContainer {
          .tilt {
            > .inner {
              background-color: rgba(31, 30, 39) !important;
            }
          }
        }
      }

      .ThirdLevelContainer {
        .ThirdLevel {
          min-height: 430px;
          opacity: 0;
          background-color: rgb(19, 19, 19);
          z-index: -1;

          &.active {
            opacity: 1;
            z-index: 1;
          }

          .ThirdLevelItem {
            margin-bottom: 10px;
            max-width: 420px;

            a {
              color: $clrMustard;
            }
          }
        }
      }
    }
  }

  &:hover {
    .TopLevel {
      > .TiltContainer {
        opacity: 1;
      }

      a {
        color: $clrMustard;
      }

      .dropDownArrow {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);

        svg {
          path {
            fill: $clrMustard;
          }
        }
      }
    }

    .SecondLevel {
      visibility: visible;
      grid-gap: 60px;
    }
  }

  .TopLevel {
    position: relative;
    display: -ms-grid;
    display: grid;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: auto 10px auto;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    padding: 10px;

    > .TiltContainer {
      opacity: 0;
    }

    a {
      color: $clrBlack;
      position: relative;
    }

    .dropDownArrow {
      svg {
        path {
          fill: $clrBlack;
        }
      }
    }
  }

  .SecondLevel {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: -25%;
    width: 150%;
    padding: 50px;
    padding-top: 70px;
    padding-bottom: 0px;
    display: -ms-grid;
    display: grid;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-gap: 10px;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;

    > .TiltContainer {
      top: 20px;
      height: calc(100% - 30px);
    }

    .mobile-title {
      display: none;
    }

    .SecondLevelItem {
      position: relative;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 10px auto;
      grid-template-columns: 1fr auto;
      grid-gap: 10px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      padding: 10px;
      margin-bottom: 10px;

      &:hover,
      &.active {
        .TiltContainer {
          .tilt {
            > .inner {
              background-color: rgb(31, 30, 39) !important;
            }
          }
        }
      }

      .TiltContainer {
        .tilt {
          > .inner {
            background-color: rgba(0, 0, 0, 0) !important;
          }
        }
      }

      a {
        position: relative;
        color: $clrMustard;
      }

      .dropDownArrow {
        display: none;
        rotate: -90deg;
        width: 14px;

        svg {
          path {
            fill: $clrMustard;
          }
        }
      }
    }

    .ThirdLevelContainer {
      display: -ms-grid;
      display: grid;
      grid-template-areas: 'stack';

      .mobile-title,
      .view-all {
        display: none;
      }

      .ThirdLevel {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-area: stack;
        position: relative;

        .ThirdLevelItem {
          position: relative;
          padding: 5px 10px;

          .TiltContainer {
            .tilt {
              > .inner {
                background-color: rgba(0, 0, 0, 0) !important;
              }
            }
          }

          a {
            color: $clrMustard;
            position: relative;
          }

          &:hover {
            .TiltContainer {
              .tilt {
                > .inner {
                  background-color: rgb(31, 30, 39) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileMediumLimitWidth) {
  .Button {
    > .inner {
      p,
      a {
        img {
          width: 25px;
        }
      }
    }
  }
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@media (max-width: 1400px) {
  .navigation-item {
    &.what-we-do {
      .SecondLevel {
        left: -200%;
      }
    }
  }
}

@media (max-width: 900px) {
  .navigation-item,
  .navigation-item.what-we-do {
    &:hover {
      .TopLevel {
        .TiltContainer {
          .tilt {
            > .inner {
              background-color: rgb(31, 30, 39) !important;
            }
          }
        }

        .dropDownArrow {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      }
    }

    .TopLevel {
      grid-template-columns: 1fr;

      a {
        color: $clrMustard;
        text-align: left;
        width: auto;
        margin-left: 0;
      }

      .TiltContainer {
        opacity: 1;

        .tilt {
          > .inner {
            background-color: #1f1e27 !important;
          }
        }
      }

      .dropDownArrow {
        rotate: -90deg;
        width: 14px;
        position: absolute;
        right: 10px;
        top: 50%;
        translate: 0 -50%;

        svg {
          path {
            fill: $clrMustard;
          }
        }
      }
    }

    .SecondLevel {
      visibility: visible;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px 0;
      display: block;
      background-color: $clrBlack;
      height: calc(100vh - 200px);

      .mobile-title {
        display: inline-block;
        text-align: center;
        margin-bottom: 30px;
        color: $clrMustard;
        padding: 0 10px;
        position: relative;
        max-width: 350px;
        width: 100%;

        .dropDownArrow {
          display: inline-block;
          width: 14px;
          height: 15px;
          position: absolute;
          left: 0;
          top: 50%;
          translate: 0 -50%;
          rotate: 90deg;
        }
      }

      .secondLevelItems {
        display: -ms-grid;
        display: grid;
        grid-gap: 10px;
        max-width: 350px;
      }

      .SecondLevelItem {
        grid-template-columns: 1fr;
        margin: 0;
        padding: 0 10px;

        .TiltContainer {
          display: none;
        }

        a {
          position: relative;
          color: $clrMustard;
          text-align: left;
          width: auto;
          margin-left: 0;
        }

        .dropDownArrow {
          display: block;
          rotate: -90deg;
          width: 14px;
          height: 14px;
          position: absolute;
          right: 10px;
          top: 50%;
          translate: 0 -50%;

          svg {
            path {
              fill: $clrMustard;
            }
          }
        }
      }

      .TiltContainer {
        display: none;
      }

      .ThirdLevelContainer {
        background: none !important;
        display: -ms-grid;
        display: grid;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 200px);
        background-color: $clrBlack !important;
        padding: 20px 0;
        translate: 100% 0;
        -webkit-transition: translate 500ms ease;
        -o-transition: translate 500ms ease;
        transition: translate 500ms ease;
        -ms-flex-line-pack: stretch;
        align-content: stretch;

        .mobile-title,
        .view-all {
          display: block;
          text-align: center;
          margin-bottom: 10px;
          color: $clrMustard;
          padding: 0 20px 10px 20px;
          text-align: left;
          max-width: 350px;

          &.brand {
            color: $clrPink !important;
          }

          &.marketing {
            color: $clrRed !important;
          }

          &.technology {
            color: $clrBlue !important;
          }
        }

        .view-all {
          padding: 0;
        }

        .mobile-title {
          text-align: center;
          position: relative;
          margin-bottom: 30px;

          .dropDownArrow {
            display: inline-block;
            width: 14px;
            height: 14px;
            position: absolute;
            left: 0;
            top: 50%;
            translate: 0 -100%;
            rotate: 90deg;
          }
        }

        .ThirdLevelItem {
          a {
            padding: 0px 20px;
            text-align: left;
          }
        }

        &.open {
          translate: 0 0;

          .ThirdLevel {
            background-color: unset;
            height: 100%;
          }
        }
      }
    }
  }

  .navigation-item.what-we-do {
    &:hover {
      .TopLevel {
        .TiltContainer {
          .tilt {
            > .inner {
              background-color: rgb(31, 30, 39) !important;
            }
          }
        }

        a {
          color: $clrMustard;
        }

        .dropDownArrow {
          svg {
            path {
              fill: $clrMustard;
            }
          }
        }
      }
    }

    .SecondLevel {
      .SecondLevelItem {
        padding: 10px;

        .TiltContainer {
          display: block;
        }

        &:hover,
        &.active {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            .TiltContainer {
              .tilt {
                > .inner {
                  background-color: rgb(31, 30, 39) !important;
                }
              }
            }
          }

          a {
            color: $clrMustard;
          }

          .dropDownArrow {
            svg {
              path {
                fill: $clrMustard;
              }
            }
          }
        }
      }
    }
  }
}
