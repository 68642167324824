@import './Utilities/Variables';

*,
::after,
::before {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 100vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0;
  font-family: $fntDefault;
  font-weight: 400;
  color: $clrBlack;
  z-index: 2;
  border: none;
  outline: none;
  text-decoration: none;
  font-style: none;
  display: block;
  grid-gap: 0px;
  grid-template-columns: 1fr;
  -ms-flex-line-pack: center;
  //align-content: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

html {
  display: block;
  overflow-x: hidden;
}

head {
  display: none;
}

body {
  background-color: $clrBlack;
  min-height: 100vh;
  display: block;
}

main {
  min-height: 100vh;
}

#root {
  overflow-x: hidden;
}

header {
  z-index: 9;
  position: fixed;
  background-color: $clrMustard;
  transition: translate 500ms ease;
  translate: 0 -100%;

  &.active {
    overflow: visible;
    height: auto !important;
  }

  &.show {
    translate: 0 0;
  }
}

h1,
.font-h1 {
  font-size: 60px;
  line-height: 68px;
  letter-spacing: 1px;
  font-family: $fntDisplay;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

h2,
.font-h2 {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 1px;
  font-family: $fntDisplay;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

h3,
.font-h3 {
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 1px;
  font-family: $fntDisplay;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

h4,
.font-h4 {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 1px;
  font-family: $fntDisplay;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

h5,
.font-h5 {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 1px;
  font-family: $fntDisplay;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

h6,
.font-h6 {
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 1px;
  font-family: $fntDisplay;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

p,
a,
label,
input,
textarea,
li,
.font-regular,
.font-regular * {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.5px;
  font-family: $fntDefault;
  display: inline-block;
  vertical-align: middle;
}

p a {
  display: inline;
  vertical-align: top;
}

strong,
b,
.font-weight-bold,
.font-weight-bold * {
  font-weight: 600 !important;
}

.font-weight-regular,
.font-weight-regular * {
  font-weight: 400 !important;
}

.font-weight-light,
.font-weight-light * {
  font-weight: 200 !important;
}

.italic {
  font-style: italic;
}

i,
em {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-family: inherit;
  display: inline;
  vertical-align: middle;
  font-style: italic;
}

span {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-family: inherit;
  display: inline;
  vertical-align: middle;
  font-weight: inherit;
}

input,
textarea {
  outline: none;
  background: none;
  appearance: none;
  border: none;
}

.p-xLarge,
.font-xLarge,
.font-xLarge * {
  font-size: 35px;
  line-height: 47px;
  letter-spacing: 1px;
}

.p-large,
.font-large,
.font-large * {
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 1px;
}

.p-small,
.font-small,
.font-small * {
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.5px;
}

.p-xSmall,
.font-xSmall,
.font-xSmall * {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.font-display,
.font-display * {
  font-family: $fntDisplay !important;
}

.font-default,
.font-default * {
  font-family: $fntDefault !important;
}

.clrBlack,
.clrBlack * {
  color: $clrBlack !important;
}

.clrWhite,
.clrWhite * {
  color: $clrWhite !important;
}

.clrMustard,
.clrMustard * {
  color: $clrMustard !important;
}

.clrRed,
.clrRed * {
  color: $clrRed !important;
}

.clrPink,
.clrPink * {
  color: $clrPink !important;
}

.clrBlue,
.clrBlue * {
  color: $clrBlue !important;
}

.clrLightMustard,
.clrLightMustard * {
  color: $clrLightMustard !important;
}

.outer {
  position: relative;
  max-width: $outerContentUpperLimitWidth;
}

.inner {
  padding: 0 $desktopMarginWidth;
  max-width: calc($innerContentUpperLimitWidth + (2 * $desktopMarginWidth));
}

.overflow-hidden {
  overflow: hidden;
}

.display-none {
  display: none;
}

.cursor-default,
.cursor-default * {
  cursor: default !important;
}

.cursor-pointer,
.cursor-pointer * {
  cursor: pointer;
}

.hide-mobile {
  display: inline-block !important;
}
.hide-desktop {
  display: none !important;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.PageLoader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  background-color: $clrMustard;
  z-index: 999;

  > .outer {
    height: 100%;

    > .inner {
      height: 100%;

      .pageLoaderContent {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 300px;
        height: 63px;
        background-color: rgb(250, 215, 98);

        .cover {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 1px;
          height: 0;
          background-color: $clrBlack;
          z-index: 10;
        }

        .PageLoaderLogo {
          .ImageContainer {
            > .inner {
              img {
                -o-object-fit: contain;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $tabletLimitWidth) {
  h1,
  .font-h1 {
    font-size: 60px;
    line-height: 70px;
  }
  h2,
  .font-h2 {
    font-size: 50px;
    line-height: 60px;
  }
  h3 {
    font-size: 40px;
    line-height: 50px;
  }
  h4,
  .font-h4 {
    font-size: 35px;
    line-height: 45px;
  }
  h5,
  .font-h5 {
    font-size: 30px;
    line-height: 40px;
  }
  h6 {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: $mobileLargeLimitWidth) {
  .inner {
    //padding: 0 25px;
    padding: 0;
  }
  h1,
  .font-h1 {
    font-size: 46px;
    line-height: 54px;
  }
  h2,
  .font-h2 {
    font-size: 40px;
    line-height: 50px;
  }
  h3 {
    font-size: 35px;
    line-height: 45px;
  }
  h4,
  .font-h4 {
    font-size: 30px;
    line-height: 40px;
  }
  h5,
  .font-h5 {
    font-size: 25px;
    line-height: 35px;
  }
  h6 {
    font-size: 20px;
    line-height: 30px;
  }
  p,
  a,
  label,
  input,
  li,
  .font-regular,
  .font-regular * {
    font-size: 15px;
    line-height: 25px;
  }
  .p-xLarge,
  .font-xLarge,
  .font-xLarge * {
    font-size: 20px;
    line-height: 30px;
  }
  .p-large,
  .font-large,
  .font-large * {
    font-size: 20px;
    line-height: 30px;
  }

  .hide-mobile {
    display: none !important;
  }
  .hide-desktop {
    display: block !important;
  }

  .PageLoader {
    > .outer {
      > .inner {
        .pageLoaderContent {
          .PageLoaderLogo {
            width: 80%;
            max-width: 300px;

            .ImageContainer {
              > .inner {
                img {
                  -o-object-fit: contain;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileMediumLimitWidth) {
  h1,
  .font-h1 {
    font-size: 42px;
    line-height: 50px;
  }
  h2,
  .font-h2 {
    font-size: 35px;
    line-height: 45px;
  }
  h3 {
    font-size: 30px;
    line-height: 40px;
  }
  h4,
  .font-h4 {
    font-size: 25px;
    line-height: 35px;
  }
  h5,
  .font-h5 {
    font-size: 20px;
    line-height: 30px;
  }
  h6 {
    font-size: 15px;
    line-height: 25px;
  }
  p,
  a,
  label,
  input {
    font-size: 15px;
    line-height: 25px;
  }
  .p-xLarge {
    font-size: 20px;
    line-height: 30px;
  }
  .p-large {
    font-size: 20px;
    line-height: 30px;
  }
  .p-small {
    font-size: 12px;
    line-height: 22px;
  }
  .p-xSmall {
    font-size: 8px;
    line-height: 18px;
  }
}

.testInner {
  position: relative;
}

.imageCover {
  position: absolute;
  left: 0px;
  left: 0px;
  height: 100%;
  width: 0px;
  z-index: 10;
}

.ImageContainer {
  position: relative;
}

.boldMe {
  font-family: 'EB Garamond', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.textSectionOne,
.textSectionTwo,
.textSectionThree,
.textSectionFour {
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  display: block;
}

.textSectionFour {
  margin-top: 40px;
  display: block;
}

.topSection {
  display: block;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
}

.topSection .contentContainer {
  -webkit-transform: translateY(80px);
  transform: translateY(80px);
  z-index: 100;
  position: relative;
}

.Header {
  display: block;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.TestimonialSection {
  z-index: 10;
}

// .pin-spacer {
//   min-height: 400px;
// }

@media (max-width: 900px) {
  header {
    &.active {
      .Header {
        background-color: #1f1e27;

        > .outer {
          > .inner {
            .logo {
              .ImageContainer {
                &:first-child {
                  display: none;
                }

                &:last-child {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
