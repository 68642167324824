@import '../../Utilities/Variables';

.StackCarousel {
  > .outer {
    max-width: 1440px;
    width: 90%;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    grid-template-areas:
      'arrowLeft carousel arrowRight'
      'dots dots dots';
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-gap: 100px;
    margin-left: 0;

    > .inner {
      grid-area: carousel;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: 'stack';
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .item {
        grid-area: stack;
        opacity: 0;
        z-index: 0;
        -webkit-transition: opacity 200ms ease;
        transition: opacity 200ms ease;

        &.active {
          opacity: 1;
          z-index: 2;
        }
      }
    }

    .arrowContainer {
      height: 100%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: relative;

      &.left {
        grid-area: arrowLeft;

        > .inner {
          padding: 0;

          .arrow {
            > .inner {
              padding: 0;
              border-bottom: 10px solid transparent;
              border-top: 10px solid transparent;
              border-right: 10px solid $clrMustard;
            }
          }
        }
      }

      &.right {
        grid-area: arrowRight;

        > .inner {
          padding: 0;
          .arrow {
            > .inner {
              padding: 0;
              border-bottom: 10px solid transparent;
              border-top: 10px solid transparent;
              border-left: 10px solid $clrMustard;
            }
          }
        }
      }

      > .inner {
        height: 50px;
        padding: 0;

        .arrow {
          height: 100%;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          cursor: pointer;

          > .inner {
            width: 0px;
            height: 20px;
            border-radius: 3px;
            padding: 0;
          }
        }
      }
    }

    .dotContainer {
      grid-area: dots;

      > .inner {
        grid-gap: 20px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        .dot {
          width: 20px;
          height: 20px;
          opacity: 0.3;
          -webkit-transition: opacity 200ms ease;
          transition: opacity 200ms ease;
          cursor: pointer;

          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
}

.TabbedCarousel {
  overflow: hidden;

  > .outer {
    > .inner {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: 'stack';
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .item {
        grid-area: stack;
        opacity: 0;
        z-index: 0;
        -webkit-transition: opacity 1000ms ease;
        transition: opacity 1000ms ease;

        &.active {
          opacity: 1;
          z-index: 2;
        }
      }
    }

    .tabContainer {
      position: absolute;
      height: 100%;
      width: auto;
      display: inline-block;
      top: 0;
      right: 5%;

      > .inner {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 20px;
        height: 100%;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .tab {
          > .inner {
            cursor: pointer;

            .dot {
              display: inline-block;
              vertical-align: middle;
              margin-right: 10px;
              width: auto;
              opacity: 0.3;
              -webkit-transition: opacity 1000ms ease;
              transition: opacity 1000ms ease;

              > .inner {
                width: 20px;
                height: 20px;

                .tilt {
                  height: 100%;
                  width: 100%;
                  top: 0;
                  left: 0;
                }
              }
            }

            p {
              display: inline-block;
              vertical-align: middle;
              width: auto;
              color: $clrWhite;
              opacity: 0.3;
              -webkit-transition: opacity 1000ms ease;
              transition: opacity 1000ms ease;
            }
          }

          &.active {
            > .inner {
              .dot {
                opacity: 1;
              }

              p {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.SlideCarousel {
  grid-gap: 50px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  overflow: visible;
  padding-top: 100px;
  display: grid;

  > .outer {
    grid-template-columns: 50px 50px 1fr;
    grid-template-areas:
      'carousel carousel carousel'
      'arrowLeft arrowRight cta';
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    grid-gap: 50px 30px;
    padding-bottom: 30px;
    display: grid;

    > .inner {
      grid-gap: 100px;
      grid-area: carousel;
      width: auto;
      max-height: 700px;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start;
      -webkit-transition: -webkit-transform 1000ms ease;
      transition: -webkit-transform 1000ms ease;
      transition: transform 1000ms ease;
      transition: transform 1000ms ease, -webkit-transform 1000ms ease;
      padding: 0;
      display: grid;
    }

    .arrowContainer {
      height: 100%;
      grid-template-columns: 50px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      z-index: 3;
      position: relative;
      display: grid;

      &.left {
        grid-area: arrowLeft;

        > .inner {
          .arrow {
            display: grid;

            > .inner {
              border-bottom: 10px solid transparent;
              border-top: 10px solid transparent;
              border-right: 10px solid $clrMustard;
            }
          }
        }
      }

      &.right {
        grid-area: arrowRight;

        > .inner {
          .arrow {
            display: grid;

            > .inner {
              border-bottom: 10px solid transparent;
              border-top: 10px solid transparent;
              border-left: 10px solid $clrMustard;
            }
          }
        }
      }

      > .inner {
        height: 50px;
        width: 50px;
        padding: 0;

        .arrow {
          height: 100%;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          cursor: pointer;
          width: 50px;

          > .inner {
            width: 0px;
            height: 20px;
            border-radius: 3px;
            padding: 0;
          }
        }
      }
    }

    .dotContainer {
      grid-area: dots;

      > .inner {
        display: grid;
        grid-gap: 20px;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .dot {
          width: 20px;
          height: 20px;
          opacity: 0.3;
          -webkit-transition: opacity 200ms ease;
          transition: opacity 200ms ease;
          cursor: pointer;

          &.active {
            opacity: 1;
          }
        }
      }
    }

    > .Button {
      max-width: 1440px;
      text-align: right;
      grid-area: cta;
      margin-right: 0;
    }
  }
}

.SlideCarouselBlogCard {
  > .inner {
    padding: 0;

    .contentContainer {
      height: 450px;
      width: 750px;
      position: relative;

      .imageParent {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

        .logoContainer {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          padding: 30px 50px;

          .ImageContainer {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: start;

            img {
              -o-object-fit: contain;
              object-fit: contain;
              max-height: 40px;
              width: auto;
              margin-left: 0;
            }
          }
        }

        > .ImageContainer {
          height: 450px;

          > .inner {
            height: 450px;

            img {
              height: 450px;
            }
          }
        }
      }

      > .inner {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 20px;

        > .TiltContainer .tilt {
          width: 105%;
          height: 105%;
          top: -2.5%;
          left: -2.5%;
        }

        p {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          position: relative;

          span.tilt {
            display: inline;
            vertical-align: top;
            color: #13121c !important;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

.SlideTestimonialCard {
  width: 90vw;
  max-width: 1100px;
  min-width: 300px;

  > .inner {
    padding: 0;
    position: relative;

    > .TiltContainer {
      .tilt {
        width: 105%;
        left: -2.5%;

        > .inner {
          border: 1px solid $clrMustard;
        }
      }
    }

    .backgroundContainer {
      position: absolute;
      height: 90%;
      top: 5%;
      left: 0;
      display: block;

      > .inner {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          'topLeft topRight'
          'bottomLeft bottomRight';
        height: 100%;
        -ms-flex-line-pack: justify;
        align-content: space-between;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 40px;
        display: grid;

        > .ImageContainer {
          > .inner {
            img {
              height: 80px;
              width: auto;
              -o-object-fit: contain;
              object-fit: contain;
            }
          }
        }

        > .ImageContainer:nth-child(1) {
          grid-area: topLeft;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: start;
          display: grid;

          > .inner {
            img {
              margin-left: 0;
            }
          }
        }

        > .ImageContainer:nth-child(2) {
          grid-area: bottomRight;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: end;
          display: grid;

          > .inner {
            img {
              margin-right: 0;
            }
          }
        }
      }
    }

    .contentContainer {
      padding: 50px;

      > .inner {
        grid-gap: 20px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: grid;
      }
    }

    .cardAuthorContainer {
      position: absolute;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      left: 0;
      bottom: 0;
      display: grid;

      > .inner {
        display: inline-block;
        width: auto;
        max-width: 80%;
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        padding: 0;

        p {
          text-align: center;
          padding: 10px;
          position: relative;
        }
      }
    }
  }
}

.StackTestimonialCard {
  > .inner {
    > .tiltCotnainer {
      .tilt {
        width: 110%;
        left: -5%;

        > .inner {
          border: 1px solid $clrMustard;
        }
      }
    }

    .backgroundContainer {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;

      > .inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          'topLeft topRight'
          'bottomLeft bottomRight';
        height: 100%;
        -ms-flex-line-pack: justify;
        align-content: space-between;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > .ImageContainer:nth-child(1) {
          grid-area: topLeft;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: start;
        }

        > .ImageContainer:nth-child(2) {
          grid-area: bottomRight;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: end;
        }
      }
    }

    .contentContainer {
      padding: 50px;

      > .inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        h3 {
          font-size: 40px;
          line-height: 50px;
          letter-spacing: 1px;
          font-weight: 600;
        }
      }
    }
    .cardAuthorContainer {
      display: grid;
      position: absolute;
      grid-template-columns: 1fr;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      left: 0;
      bottom: 0;

      > .inner {
        display: inline-block;
        width: auto;
        max-width: 80%;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);

        p {
          text-align: center;
          padding: 10px;
          font-weight: 600;
        }
      }
    }
  }
}

.TabbedAboutCard {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 200px 0;
  position: relative;
  display: grid;
  min-height: 100vh;
  z-index: 12;
  position: relative;

  .background {
    position: absolute;
    height: 100%;
    width: 300vw;
    top: 0;
    left: -100vw;
    max-width: unset;
  }

  > .inner {
    grid-template-columns: 5fr 6fr;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    display: grid;

    .imageParent {
      max-height: 600px;

      > .inner {
        padding: 0;
      }
    }

    .contentContainer {
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px);

      > .inner {
        padding: 0;
        padding-top: 50px;
        position: relative;

        .title {
          display: inline-block;
          width: auto;
          position: absolute;
          top: -50px;
          left: -25px;

          > .inner {
            padding: 0;

            h3 {
              padding: 0px 20px;
              position: relative;
            }
          }
        }

        .copy {
          position: relative;

          > .inner {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: start;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 20px 40px;
            display: grid;

            P {
              text-align: left;
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            > .Button {
              margin-left: 0;

              p,
              a {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.auto-scroll-client-carousel {
  position: relative;
  padding: 80px 0;

  > .background {
    background-color: $clrBlack;
    width: 300vw;
    left: -100vw;
    max-width: unset;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -46px;
      right: 50vw;
      height: 0;
      border-style: solid;
      border-width: 0px 0px 54px 198vw;
      border-color: transparent transparent #13121c transparent;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -46px;
      right: 50vw;
      height: 0;
      border-style: solid;
      border-width: 0px 0px 54px 198vw;
      border-color: transparent transparent #13121c transparent;
      rotate: 180deg;
    }
  }

  > .inner {
    .title {
      color: $clrMustard;
      margin-bottom: 100px;
      text-align: center;
      padding-left: 50px;
      padding-right: 50px;
    }

    .carousel {
      cursor: grab;
      position: relative;
      padding: 25px 0 50px 0;

      &.dragging {
        cursor: grabbing;
      }

      > .background {
        &.left,
        &.right {
          z-index: 9;
          width: 25vw;
        }

        &.left {
          left: -25vw;
          background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, $clrBlack 80%, $clrBlack 100%);
        }

        &.right {
          margin-right: 0;
          right: -25vw;
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, $clrBlack 80%, $clrBlack 100%);
        }
      }

      > .inner {
        display: grid;
        grid-gap: clamp(50px, 10vw, 150px);
        max-width: unset;
        width: auto;
        align-content: center;
        justify-content: start;
        align-items: center;
        position: relative;

        > div {
          display: grid;
          grid-gap: clamp(50px, 10vw, 150px);
          max-width: unset;
          align-content: center;
          justify-content: center;
          align-items: center;

          .ImageContainer {
            height: 100%;
            overflow: visible;

            > .inner {
              img {
                width: auto;
                max-height: 65px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $desktopSmallLimitWidth) {
  .TabbedAboutCard {
    > .inner {
      grid-template-columns: 1fr;
      max-width: 600px;

      .contentContainer {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);

        > .inner {
          padding-top: 30px;
          width: 110%;
          left: -5%;
        }
      }
    }
  }
}

@media (max-width: $tabletLimitWidth) {
  .SlideCarouselBlogCard {
    > .inner {
      .contentContainer {
        width: 500px;
      }
    }
  }

  .StackTestimonialCard {
    > .inner {
      .contentContainer {
        padding: 30px;
        padding-bottom: 50px;
      }
    }
  }
}

@media (max-width: $mobileLargeLimitWidth) {
  .SlideCarousel {
    padding-top: 0px;
    grid-gap: 30px;

    > .outer {
      grid-template-columns: 1fr !important;
      grid-template-areas: 'carousel' 'cta';
      max-width: calc(100vw - (2 * $mobileMarginWidth));
      grid-gap: 50px;

      .arrowContainer {
        display: none;
      }

      > .inner {
        grid-gap: 50px;
      }

      > .Button {
        max-width: 90vw;
        margin-left: 0vw;
        text-align: center;
      }
    }
  }

  .TabbedCarousel {
    overflow: hidden;

    > .outer {
      .tabContainer {
        right: unset !important;
        height: auto;
        top: unset;
        bottom: 50px;
        width: 100%;

        > .inner {
          display: block;
          width: 90%;
          text-align: center;

          .tab {
            display: inline-block;
            vertical-align: middle;
            width: auto;
            margin: 0px 20px 20px 20px;

            > .inner {
              p {
                opacity: 0.3;
              }
            }
          }
        }
      }
    }
  }

  .StackCarousel {
    > .outer {
      grid-template-columns: 1fr;
      grid-template-areas:
        'carousel'
        'dots';
      grid-gap: 100px 50px;

      .arrowContainer {
        display: none;
      }
    }
  }

  .StackTestimonialCard {
    > .inner {
      > .tiltContainer {
        .tilt {
          width: 100%;
          left: -0%;
          height: 110%;
          top: -5%;
        }
      }

      .backgroundContainer {
        > .inner {
          padding: 20px 0;

          > .ImageContainer {
            img {
              height: 60px;
            }
          }
        }
      }

      .contentContainer {
        padding: 20px;

        > .inner {
          h3 {
            text-align: center;
            font-size: 25px;
            line-height: 35px;
          }

          p {
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .cardAuthorContainer {
        > .inner {
          width: 80%;
          max-width: 300px;
          -webkit-transform: translateY(75%);
          transform: translateY(75%);
        }
      }
    }
  }

  .TabbedAboutCard {
    padding: 150px 0;

    > .inner {
      grid-template-columns: 1fr;

      .imageParent {
        overflow: hidden;
        margin-left: -10vw;
      }

      .contentContainer {
        -webkit-transform: translateY(30%);
        transform: translateY(30%);

        > .inner {
          padding-top: 30px;
          width: 100%;
          left: 0;

          .title {
            top: -45px;
            left: -10px;
          }

          .copy {
            > .inner {
              P {
                -webkit-line-clamp: 4;
              }
            }
          }
        }
      }
    }
  }

  .SlideCarouselBlogCard {
    > .inner {
      .contentContainer {
        height: 400px;
        width: 350px;

        .imageParent {
          .logoContainer {
            padding: 30px;

            > .ImageContainer > .inner img {
              max-height: 40px;
            }
          }

          > .ImageContainer {
            height: 400px;

            > .inner {
              height: 400px;

              img {
                height: 400px;
              }
            }
          }
        }
      }
    }
  }

  .SlideTestimonialCard {
    > .inner {
      .backgroundContainer {
        > .inner {
          > .ImageContainer {
            > .inner {
              img {
                height: 60px;
              }
            }
          }
        }
      }

      .contentContainer {
        padding: 20px;
        padding-bottom: 60px;
      }

      .TiltContainer {
        .tilt {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  .auto-scroll-client-carousel {
    padding: 80px 0 0 0;

    > .inner {
      .title {
        margin-bottom: 50px;
      }

      .carousel {
        > .inner {
          > div {
            .ImageContainer {
              > .inner {
                img {
                  max-height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileMediumLimitWidth) {
  .SlideCarouselBlogCard {
    > .inner {
      .contentContainer {
        height: 350px;
        width: 300px;

        .imageParent {
          > .ImageContainer {
            height: 350px;

            > .inner {
              height: 350px;

              img {
                height: 350px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileSmallLimitWidth) {
  .TabbedCarousel {
    > .outer {
      .tabContainer {
        > .inner {
          .tab {
            margin: 0px 10px 20px 10px;
          }
        }
      }
    }
  }

  .SlideCarouselBlogCard {
    > .inner {
      .contentContainer {
        width: 250px;
      }
    }
  }
}

.test {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media (min-width: 2200px) {
  .auto-scroll-client-carousel {
    > .inner {
      .carousel {
        > .background {
          &.left,
          &.right {
            display: none;
          }
        }
      }
    }
  }
}
