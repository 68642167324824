@import './Utilities/Variables';

.App {
  display: block;

  > div {
    grid-gap: 50px;
    // align-content: center;
    // justify-content: center;
  }
}
