@import '../../Utilities/Variables';

.Footer {
  background-color: $clrBlack;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    right: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 0px 20px 98vw;
    border-color: transparent transparent $clrBlack transparent;
  }

  > .outer {
    > .inner {
      max-width: 1720px;

      .upper {
        padding: 100px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);

        > .inner {
          position: unset;
          padding: 0;

          nav {
            > .inner {
              display: grid;
              max-width: 600px;
              padding: 0;

              .Button {
                .TiltContainer {
                  -webkit-transition: opacity 200ms ease;
                  transition: opacity 200ms ease;
                  opacity: 0.1;
                }

                &:hover {
                  .TiltContainer {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }
      }

      .lower {
        padding: 30px 0;

        > .inner {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 50px;
          padding: 0;
          display: grid;
          max-width: 1720px;

          .left {
            > .inner {
              grid-template-columns: repeat(2, auto);
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: start;
              grid-gap: 30px;
              padding: 0;
              display: grid;

              .ImageContainer {
                > .inner {
                  height: 50px;
                }
              }
            }
          }

          .right {
            > .inner {
              padding: 0;

              nav {
                > .inner {
                  grid-template-columns: repeat(3, auto);
                  -webkit-box-pack: end;
                  -ms-flex-pack: end;
                  justify-content: end;
                  grid-gap: 0px;
                  padding: 0;
                  display: grid;

                  a {
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.TermsConditions {
  background-color: $clrBlack;
  position: relative;

  > .outer {
    > .inner {
      padding: 10px 50px;
      max-width: 1720px;
      text-align: right;
    }
  }
}

@media (max-width: $tabletLimitWidth) {
  .Footer {
    > .outer {
      > .inner {
        .upper {
          padding: 30px 0;

          > .inner {
            nav {
              > .inner {
                display: block;
                text-align: center;

                .Button {
                  display: inline-block;
                  width: auto;
                  margin: 20px;

                  > .outer {
                    width: auto;
                    display: inline-block;
                    margin: 0 30px 30px 30px;
                  }
                }
              }
            }
          }
        }

        .lower {
          > .inner {
            grid-template-columns: repeat(1, 1fr);
            grid-template-areas:
              'top'
              'bottom';
            grid-gap: 30px;

            .left {
              grid-area: bottom;

              > .inner {
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
              }
            }

            .right {
              grid-area: top;

              > .inner {
                nav {
                  > .inner {
                    display: block;
                    text-align: center;

                    .Button {
                      display: inline-block;
                      width: auto;

                      .outer {
                        display: inline-block;
                        width: auto;
                        margin: 0 10px 10px 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .TermsConditions {
    > .outer {
      > .inner {
        text-align: center;
      }
    }
  }
}
